<template>
  <div class="login">
    <nut-navbar
    :left-show="false"
      :title="$t('userLogin.signIn')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
      <template #left>
        <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
      </template>
    </nut-navbar>

    <div class="login-bn"></div>
    <div class="login-bd">

      <h3 class="s4"></h3>
      
      <div class="login-inp">
        <label class="fs12 fc-999">{{ $t('userLogin.mobileNumber')}}</label>
        <p class="err-info" v-show="errText.mobile">{{errText.mobile}}</p>
        <div class="nation">+91</div>
        <login-input
          label=""
          :placeholder="$t('userLogin.mobileNumberPlaceholder')"
          type="number"
          rule="^.{1,18}$"
          class="pl40"
          autocomplete="off"
          @inputChange="(res) => (mobile = res)"
          @blur="inpBlur(mobile)"
          v-model="mobile"
        />
      </div>

      <div class="login-inp">
        <label class="fs12 fc-999">{{ $t('userLogin.password')}}</label>
        <p class="err-info" v-show="errText.password">{{errText.password}}</p>
        <span class="f-r" @click="forgotPasswordClick">{{ $t('userLogin.forgotPassword')}}</span>
        <login-input
          label=""
          :placeholder="$t('userLogin.passwordPlaceholder')"
          type="password"
          rule="^.{1,32}$"
          maxLength="32"
          autocomplete="off"
          @inputChange="(res) => (password = res)"
        />
      </div>

      <div class="login-btn">
        <nut-button block type="info" @click="loginSubmit">{{ $t('userLogin.login')}}</nut-button>
      </div>

      <nut-row>
        <nut-col :span="24">
          <div class="flex-content" style="text-align: center;">
            <span class="a-lk" @click="registerClick">{{ $t('userLogin.register')}}</span>
          </div>
        </nut-col>
        <!-- <nut-col :span="12">
          <div class="flex-content ta-right">
            <span class="a-lk" @click="userLoginClick">{{ $t('userLogin.SMSLogin')}}</span>
          </div>
        </nut-col> -->
      </nut-row>

    </div>

    <img style="width:100%;" :src="require('../assets/images/global/bg145.jpg')"/>

  </div>
</template>

<script>
import { reactive,onMounted } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "@nutui/nutui";
import axios from "axios";
import loginInput from "@/components/loginInput.vue";
import { isBlank, delSpace } from "@/assets/js/commonUtil";
import { getCountry} from "@/assets/js/environment";
import {eventTrackingLogin} from "@/assets/js/eventTracking";

export default {
  name: "userLogin",
  components: {
    loginInput: loginInput,
  },
  data() {
    return{
      country: '',
      commonInfo: '',
      imgUrl: '',
      mobile: "",
      password: "",
      inviteCode: null,
      shareCode: null,
      shareBonusReceiveCode: "",
      amount: "",
      errText:{
        mobile: "",
        password: ""
      }
    };
  },
  created() {
    this.country = getCountry();
    this.imgUrl = localStorage.getItem('countryImgUrl')
    if(!isBlank(localStorage.getItem("invite"))) {
      this.inviteCode = localStorage.getItem("invite")
    }
    if(!isBlank(localStorage.getItem("shareBonusCode"))) {
      this.shareCode = localStorage.getItem("shareBonusCode")
    }
  },
  methods: {
    inpBlur(v){
        if(v.startsWith("0") && v.length > 10){
          let vv = v.substr(1)
          this.mobile = vv
        }
      },
    loginSubmit() {
      this.errText.mobile = ""
      this.errText.password = ""
      if(this.mobile === ""){
        this.errText.mobile = this.$t('global.errInfoRequired')
        return
      }
      if(this.password === ""){
        this.errText.password = this.$t('global.errInfoRequired')
        return
      }
      Toast.loading(this.$t('global.loading'), {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
      });
      this.mobile = delSpace(this.mobile)
      this.password = delSpace(this.password)
      axios
      .post("/api/user/login/password", {
        mobile: this.mobile,
        password: this.password,
        recallInviteCode: this.inviteCode,
        recallShareBonusCode: this.shareCode
      })
      .then((response) => {
        if (response.data.code === 0) {
          //埋点
          eventTrackingLogin(response.data.data.inviteCode, response.data.data.invokeFb);
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("hasPassword", response.data.data.hasPassword);
          localStorage.removeItem("invite");
            if (!isBlank(this.shareCode)) {
              if(parseInt(localStorage.getItem("needVip")) > 0){
                //this.$router.push("receiveBonus?c=" + this.shareCode);
                window.location.href = "receiveBonus?c=" + this.shareCode

              }else{
                axios
                .post("/api/user/share-bonus/receive", {
                  shareBonusCode: this.shareCode,
                })
                .then((res) => {
                  if (res.data.code === 0) {
                    this.shareBonusReceiveCode = res.data.data.shareBonusReceiveCode;
                    this.amount = res.data.data.shareBonusAmount;
                    localStorage.setItem("shareBonusReceiveCode",this.shareBonusReceiveCode);
                    localStorage.setItem("shareBonusAmount", this.amount);
                    localStorage.removeItem("shareBonusCode");
                    setTimeout(() => {
                      this.$router.push({
                        path: "/shareBonus",
                        query: {
                          amount: this.amount,
                        },
                      });
                    }, 1000);
                  } else {
                    if (res.data.msg != undefined) {
                      Toast.fail(res.data.msg);
                      this.errMsg = res.data.msg;
                    } else {
                      Toast.fail("error!");
                    }
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
              }

            } else {
              setTimeout(() => {
                this.$router.push({ path: '/lobby'});
              }, 1000);
            }

            Toast.hide("loading");
            Toast.success(this.$t('userLogin.loginSuccess'));     
        }else if (response.data.code === 1004){
          Toast.hide("loading");
          let res = response.data.data
          for(var i in res){	
            switch (res[i].fieldName) {
              case "mobile":
                this.errText.mobile = res[i].description
                break;
              case "password":
                this.errText.password = res[i].description
                break;
            }
          }
        }else{
          Toast.hide("loading");
        }
      })
      .catch(function (error) {
        console.log(error);
        Toast.hide("loading");
      });
    },
  },

  setup() {
    const state = reactive({
      runningDays: ''
    });
    const router = useRouter();
    

    const backClick = () => {
      router.push({
        name: "gameLobby",
      });
    };
    const userLoginClick = () => {
      router.push({
        name: "userSMSLogin",
      });
    };
    const registerClick = () => {
      router.push({
        name: "userRegister",
      });
    };
    const forgotPasswordClick = () => {
      router.push({
        name: "forgotPassword",
      });
    };
  
    onMounted(() => {
      if(window.localStorage.getItem('commonInfo')){
        let commonInfo = JSON.parse(window.localStorage.getItem('commonInfo'))
        state.runningDays = commonInfo.runningDays
      }else{
        axios.get("/api/system/common", {}).then((response)=> {
          state.runningDays = response.data.data.runningDays
        })
      }
    })
    return {
      state,
      backClick,
      userLoginClick,
      registerClick,
      forgotPasswordClick
    };
  },
};
</script>

<style scoped>
</style>
