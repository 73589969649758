<template>
  <div class="myHome">

    <div class="my-home-hd">
      <div class="l">
        <div class="p-avatar">
          <img class="abg" v-show="userInfo.avatar !== ''" :src="userInfo.vipBg" @click="avatarClick(userInfo.myAvatar)" />
          <img class="avatarimg" v-show="userInfo.avatar !== ''" 
            :src="userInfo.avatar"
            @click="avatarClick(userInfo.myAvatar)"/>
        </div>
        <div class="p-m">
          <span >{{ userInfo.mobile }}</span>
          <div :class="userInfo.vipLevel > 0 ? 'tx' : 'tx tx-d'"  @click="vipPopupClick">
            <span>{{$t('home.vip')}} {{ userInfo.vipLevel }}</span>
          </div>
        </div>
      </div>
      <div class="r">
          <span class="icon-b" v-show="downloadAppShow">
            <a :href="appDownloadUrl">
              <span @click="downloadAppClose">Download APP</span>
            </a>
          </span>
          <span class="icon-a" @click="iconClick"></span>
      </div>
    </div>

    <div class="my-info">

      <div class="my-info-c">

        <div class="t">
          <div class="h5">
            <span  @click="infoToast($t('home.gameAmount'))">
              {{$t('withdrawal.gameAccount')}}
              <i class="icon-new icon-i1"></i>
            </span>
            <strong>{{ $filters.currencySymbol(fundAccount.coinAccount) }}</strong>
          </div>
          <div class="btn-box" style="margin-bottom: 10px;" v-if="fundAccount.chipAccount > 0">
            <span class="btn-t" @click="rechargeClick('cash')">{{$t('home.recharge')}}</span>
            <span class="btn-t" @click="withdrawalClick('coin')">{{$t('home.withdrawal')}}</span>
          </div>
        </div>

        <div class="t b1">
          <div class="h5" v-if="fundAccount.chipAccount > 0 || (userInfo.vipLevel === 0 && fundAccount.chipAccount <= 0)">
            <span @click="infoToast('Chips are given as gifts from the platform. You can use them to experience original games. If you deposit up to ₹1000, the chip balance will be converted into game account coins and can be withdrawn.')">
              {{$t('withdrawal.chipAccount')}}
              <i class="icon-new icon-i1"></i>
            </span>
            <div v-if="fundAccount.chipAccount < 100">
              <!-- <nut-circleprogress :progress="fundAccount.chipAccount" strokeWidth="8"  radius="34" color="#ffe114">{{ $filters.currencySymbol(fundAccount.chipAccount) }}</nut-circleprogress> -->
              <strong style="margin-bottom:5px; display: block; line-height: 34px;">{{ $filters.currencySymbol(fundAccount.chipAccount) }}</strong>
              <nut-progress :percentage="fundAccount.chipAccount" stroke-color="linear-gradient(270deg, #ffe114 0%,#e49600 33%,#cd6f00 100%)" status="active" stroke-width="15"  :show-text="false" />
            </div>
            <div class="p-text" v-else >
              <strong>{{ $filters.currencySymbol(fundAccount.chipAccount) }}</strong>
              <p>Deposit ₹1000,the chip balance will convert into game account coins and can be withdrawn.</p> 
            </div>
            
          </div>
      
          <div class="btn-box" style="margin-top: 32px;" v-else>
            <span class="btn-t" @click="rechargeClick('cash')" style="width:70px;text-align: center;">{{$t('home.recharge')}}</span>
            <span class="btn-t" @click="withdrawalClick('coin')" style="width:70px; text-align: center;">{{$t('home.withdrawal')}}</span>
          </div>
          
        </div>

      </div>


      <div class="my-info-b">

        <div class="t ls" @click="vipPopupClick">
          <h5>{{$t('home.vip')}}: <strong>Lv{{ userInfo.vipLevel }}</strong></h5>
          <span>VIP Details</span>
        </div>

        <div class="t mr5"  @click="taskRewardClick">
          <h5>{{$t('global.reward')}}</h5>
          <span>{{$t('taskReward.checkIn')}} ></span>
        </div>

        <div class="t" @click="copyClick(userInfo.inviteCode)">
          <h5>{{ $t('home.inviteCode') }}</h5>
          <span>{{ userInfo.inviteCode }}<nut-icon name="link" class="copyicon"  style="font-size: 12px; vertical-align: middle;"></nut-icon></span>
        </div>

        

      </div>


    </div>

    <div class="m8" style="padding-bottom: 50px;">
      <nut-cell-group>
        <nut-cell
          :title="$t('home.link')"
          is-link
          @click="telegramClick"
          :desc="$t('home.link_r')"
          class="icon-t1"
        ></nut-cell>
        <nut-cell
          :title="$t('home.promotion')"
          is-link
          @click="promotionClick"
          class="icon-t2"
        ></nut-cell>

        <nut-cell
          :title="$t('home.envelopesCenter')"
          is-link
          :desc="unreadLetterCount"
          @click="envelopesCenterClick"
          class="icon-t3"
        ></nut-cell>

        <nut-cell
          title="Game Order"
          is-link
          :desc="unreadLetterCount"
          @click="lobbyGameHistoryListClick"
          class="icon-t13"
        ></nut-cell>

        

        <nut-cell
          :title="$t('home.transactions')"
          is-link
          @click="transactionsClick"
          class="icon-t4"
        ></nut-cell>
        <nut-cell
          :title="$t('home.bankCard')"
          is-link
          @click="bankCardClick"
          class="icon-t5"
        ></nut-cell>
        <nut-cell
          :title="$t('home.resetPassword')"
          is-link
          @click="ResetPasswordClick"
          class="icon-t6"
        ></nut-cell>

        <nut-cell
          v-if="commonInfo.supportCenterOn"
          :title="$t('HELP.H1')"
          is-link
          @click="helpSupport"
          class="icon-t11"
        ></nut-cell>

      </nut-cell-group>


     
      

      <nut-cell-group>
        <nut-cell 
          v-show="downloadAppShow"
          :title="$t('global.downloadApp')"
          is-link
          @click="downloadApp"
          class="icon-t9"
        ></nut-cell>
        <nut-cell
          :title="$t('home.logout')"
          is-link
          @click="logoutClick"
          class="icon-t12"
        ></nut-cell>
      </nut-cell-group>

    </div>

    <nut-popup
      position="bottom"
      closeable
      round
      :style="{ height: '70%' }"
      v-model:visible="showRound"
    >
      <div class="vip-popup">
        <h3>{{$t('home.vipTitle')}}</h3>

        <nut-tabs v-model="tab11value" type="smile" >
          <nut-tabpane :title="$t('home.withdrawakQuoat')">
            <table>
              <tr>
                <th>{{$t('home.level')}}</th>
                <th>{{$t('home.totalRecharge')}}</th>
                <th>{{$t('home.profitRatio')}}</th>
              </tr>
              <tr>
                <td>1</td>
                <td>&ge; 200</td>
                <td>1.25</td>
              </tr>
              <tr>
                <td>2</td>
                <td>&ge; 400</td>
                <td>1.40</td>
              </tr>
              <tr>
                <td>3</td>
                <td>&ge;1000</td>
                <td>1.45</td>
              </tr>
              <tr>
                <td>4</td>
                <td>&ge;2000</td>
                <td>1.50</td>
              </tr>
              <tr>
                <td>5</td>
                <td>&ge;10000</td>
                <td>1.55</td>
              </tr>
            </table>
          </nut-tabpane>
          <nut-tabpane :title="$t('home.withdrawalServices')"> </nut-tabpane>
          <nut-tabpane :title="$t('home.vipRewards')"> </nut-tabpane>
        </nut-tabs>

        <dl>
          <dt>{{$t('home.dt')}}</dt>
          <dd>{{$t('home.dd1')}}</dd>
          <dd>{{$t('home.dd2')}}</dd>
        </dl>
      </div>
    </nut-popup>

    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>

    <nut-overlay v-model:visible="addDesktopShow" :z-index="2000" style="" class="add-Desktop" @click="downloadAppClose">
      <div class="wrapper">
        <div class="content">
          <p>{{$t('global.addDesktopTitle')}}</p>
          <p>{{$t('global.addDesktopText1a')}} <img src="../assets/images/global/bg64.png" /> {{$t('global.addDesktopText1b')}}</p>
          <p>{{$t('global.addDesktopText2')}}</p>
        </div>
      </div>
    </nut-overlay>

    <nut-popup pop-class="pop-vip-lobby" v-model:visible="vipPopup" :z-index="100">
      <VipRules :myLevel="myLv" :popTitle="popTitle"></VipRules>
    </nut-popup>



    <nut-drag direction="y" :style="{ bottom: '60px', right:'5px', zIndex: '1000 !important'}"  @click="helpSupport" >
      <div class="drag-wb-history">
        <img :src="require('../assets/images/global_icon/202405-66.png')"/>
      </div>
    </nut-drag>


  </div>
</template>

<script>
import { ref, reactive, onMounted, toRefs,getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import {Dialog, Toast} from "@nutui/nutui";
import axios from "axios";
import { getClientType } from "@/assets/js/commonUtil";
import { getCountry } from "@/assets/js/environment";
import VipRules from "../components/VipRules.vue";
export default {
  name: "MyHome",
  components: {
    tabbar: TabbarMain,
    VipRules: VipRules
  },
  data() {
    return {
      country:'',
      clientType:'',
      downloadTitle:'',
      downloadAppShow: '',
      menuDownloadAppShow: '',
      appDownloadUrl:'',
      addDesktopShow: '',
      vipPopup:false,
      popTitle:'',
      i18n:{
        loading: this.$t('global.loading'),
        copied: this.$t('global.Your_invitation_link_has_been_copied'),
        email: this.$t('global.Through_Email1')+'@'+this.$t('global.Through_Email2'),
        ok: this.$t('global.okText')
      }
    };
  },
  methods: {
    infoToast(msg){
      Dialog({
        content: msg,
        okText: this.$t('global.okText'),
        noCancelBtn: true
      });
    },
    
    //下载app
    downloadApp(){
        window.open(this.appDownloadUrl);
    },
    downloadAppClose(){
      this.addDesktopShow = false;
      this.downloadAppShow = false;
      localStorage.setItem("downloadApp", 'close');
    },
    showDownloadTip() {
      this.clientType = getClientType();
      if (this.clientType == "ios") {
        this.addDesktopShow = true;
        this.downloadAppShow = false;
        this.downloadTitle = this.$t('global.addDesktop');
      } else if (this.clientType == "android") {
        this.addDesktopShow = false;
        this.downloadAppShow = true;
        this.menuDownloadAppShow = true;
        if (window.localStorage.getItem("commonInfo")) {
          let commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
          this.appDownloadUrl = commonInfo.appDownloadUrl;
        } else {
          axios.get("/api/system/common", {}).then((response) => {
            this.appDownloadUrl = response.data.data.appDownloadUrl;
          });
        }
        if(this.appDownloadUrl === '-') {
          this.downloadAppShow = false;
          return
        }
        this.downloadTitle = this.$t('global.downloadApp');
      }
    }
  },
  created() {
    if(localStorage.getItem("downloadApp") === 'close'){
      this.downloadAppShow = false
      this.addDesktopShow = false
    }else{
      this.showDownloadTip();
    }
    this.country = getCountry();
  }, 
  setup() {
    const _data = getCurrentInstance();
    const switchChecked = ref(true);
    const state = reactive({
      commonInfo: JSON.parse(window.localStorage.getItem("commonInfo")),
      imgUrl: "",
      icon: "notice",
      booleanTrue: true,
      showRound: false,
      tabbarActiveIndex: 4,
      tab11value: "0",
      userInfo: {
        mobile: "",
        inviteCode: "",
        avatar: "",
        vipLevel: "",
        vipTitle: "",
        vipBg: "",
        myAvatar: "",
      },
      fundAccount: {
        cashAccount: "",
        coinAccount: "",
        chipAccount: "",
      },
      unreadLetterCount: 0,
    });
    const router = useRouter();
    const methods = {
      telegramClick(){
        window.open(state.commonInfo.telegramChannelUrl,"_self");
      },
      vipPopupClick(){
        router.push({
          name: "vipDetails",
        });
      },
      //按钮-VIP
      promotionClick() {
        router.push({
          name: "agentGrowth",
        });
      },
      //按钮-充值
      rechargeClick() {
        router.push({
          name: "rechargeBalance",
        });
      },
      //按钮-提现
      withdrawalClick(t) {
        router.push({
          path: 'withdrawalBalance',
					query: {
						amountType: t
					}
				})
      },
      //转换
      transferClick() {
        router.push({
          name: "cashTransfer",
        });
      },
      //任务页
      taskRewardClick() {
        router.push({
          name: "taskReward",
        });
      },
      //按钮-领取红包
      envelopesCenterClick() {
        router.push({
          name: "envelopesCenter",
        });
      },
      //按钮-钱包
      transactionsClick() {
        router.push({
          name: "transactionsHistoryList",
        });
        router.push({
          path: "transactionsHistoryList",
          query: {
            listType: 'coin'
          }
        })
      },
      //按钮-银行卡
      bankCardClick() {
        router.push({
          name: "bankCard",
        });
      },
      //按钮-修改密码
      ResetPasswordClick() {
        router.push({
          name: "resetPassword",
        });
      },
      //按钮-投诉建议
      complaintsSuggestionsClick() {
        router.push({
          name: "complaintsSuggestions2",
        });
        // Dialog({
        //   content: _data.data.i18n.email,
        //   okText: _data.data.i18n.ok,
        //   noCancelBtn: true
        // });
      },
      helpSupport(){
        router.push({
          name: "helpSupport",
        });
      },
      //USDT充值
      rechargeUSDTClick() {
        router.push({
          name: "rechargeUSDT",
        });
      },
      //按钮-退出登录
      logoutClick() {
        localStorage.removeItem('token')
        localStorage.removeItem('hasPassword')
        localStorage.removeItem('firstVisit')
        router.push({
          name: "userLogin",
        });
      },
      

      lobbyGameHistoryListClick() {
        router.push("./lobbyGameHistoryList");
      },
      
      iconClick() {
        router.push({
          name: "envelopesCenter",
        });
      },

      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      avatarClick(url){
        router.push({
          name: "setAvatar",
          query: {img:url}
        });
      },
      copyClick(inviteCode){
        let url = state.commonInfo.frontBaseUrl
        let cInput = document.createElement("input")
        cInput.value = url+'?invite='+inviteCode
        document.body.appendChild(cInput)
        cInput.select()
        document.execCommand("copy")
        Toast.success(_data.data.i18n.copied)
        document.body.removeChild(cInput)
      },
    };
    onMounted(() => {
      methods.textToast(_data.data.i18n.loading);
      state.imgUrl = localStorage.getItem('countryImgUrl')
      //请求数据
      axios
        .get("/api/user/home", {})
        .then(function (response) {
          state.userInfo.mobile = response.data.data.user.mobile;
          state.userInfo.inviteCode = response.data.data.user.inviteCode;
          state.userInfo.myAvatar = response.data.data.user.avatar;
          state.userInfo.avatar = 
            require('../assets/images/avatar/' + response.data.data.user.avatar.slice(response.data.data.user.avatar.length-6))
          state.userInfo.vipLevel = response.data.data.user.vipLevel;
          state.fundAccount.cashAccount = response.data.data.cashAccount.availableAmount;
          state.fundAccount.coinAccount = response.data.data.coinAccount.availableAmount;
          state.fundAccount.chipAccount = response.data.data.chipAccount.availableAmount;
          //state.fundAccount.chipAccount = 30
          state.unreadLetterCount = response.data.data.unreadLetterCount;
          localStorage.setItem("inviteCode", response.data.data.user.inviteCode);
          if (response.data.data.unreadLetterCount === 0) {
            state.icon = "notice";
          }
          Toast.hide("loading");
          const vipRules = state.commonInfo.vipRules;
          for(var i = 0; i < vipRules.length; i ++){
            if(vipRules[i].level === state.userInfo.vipLevel){
              state.userInfo.vipBg = require('../assets/images/global_icon/abg-'+ vipRules[i].level +'.png')
              state.userInfo.vipTitle = vipRules[i].title
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
    });
    return {
      ...toRefs(state),
      ...methods,
      switchChecked,
    };
  },
};
</script>

<style scoped>

/* 我的主页 */
.my-home-hd{ height:50px; padding: 0px 10px; position: fixed; top: 0; left:10px; right:10px; z-index: 102; border-top:5px solid #035b40 ; border-radius: 15px 15px 0 0;
  background:linear-gradient(1turn, #228769, #279876); border-bottom:1px solid #287e64;}
.my-home-hd .l{display:inline-block; width: 40%;}
.my-home-hd .l .p-avatar { text-align: center;  padding-top: 10px; position: relative; width: 48px; margin-top:0px; display: inline-block;}
.my-home-hd .l .p-avatar img { width: 32px;height: 32px;}
.my-home-hd .l .p-avatar .abg{ width: 40px; height: 40px; position: absolute; top:6px; left: calc(50% - 20px);}
.my-home-hd .l .avatarimg{border-radius: 50%;}
.my-home-hd .l .tx { color: #ffe114; font-size: 12px; border-radius: 5px 20px 20px 5px;
   max-width: 46px;line-height: 22px; height: 20px; text-align: center;padding-left: 14px;
   background: url(../assets/images/global/user1.png) 0 0 no-repeat; background-size:auto 20px;}
.my-home-hd .l  .tx-d{color: #666;  background: url(../assets/images/global/user2.png) 0 0 no-repeat; background-size:auto 20px;}

.my-home-hd .l .p-m{ color: #eaffc6;display: inline-block;vertical-align: super;}
.my-home-hd .r {vertical-align: top; text-align: right; padding-top: 12px;}
.my-home-hd .r .icon-a{ display: inline-block; background: url(../assets/images/global_icon/202405-30b.png) 0 0 no-repeat; background-size:auto 28px;
   width: 28px; height: 28px; vertical-align: bottom;}

.my-home-hd .r .icon-b{ background: #eaffcd url(../assets/images/global_icon/202405-23.png) 7px 3px no-repeat; background-size:auto 18px; display: inline-block;width: 80px; height: 28px; line-height: 28px; 
  vertical-align: super; margin-right: 10px; font-size: 10px; text-align: center; border-radius: 15px; text-align: left; padding-left: 28px;  }
.my-home-hd .r .icon-b a{text-decoration: none; font-weight: 500; color: #000; }
.my-home-hd .r{ display:inline-block;width:60%;}


.add-Desktop .wrapper{position: absolute;bottom: 0; left: 0; right: 0; height: 190px; text-align: center; color: #fff;
  background: url(../assets/images/global/bg63.png) center bottom no-repeat; background-size:40px auto; }
.add-Desktop .wrapper p{ line-height: 20px; margin: 0; padding: 5px;}
.add-Desktop .wrapper img{ vertical-align: middle; width: 14px;}

.new-icon{position: absolute; right:7px;top:12px; width: 8px; height: 8px; border-radius: 4px; background:#f30b0b;z-index: 501; }
.nut-navbar, .nut-navbar-b0, .nut-navbar-b0 .nut-navbar {
  box-shadow: 0 0 0 !important;
}

.dot-right {
  position: absolute;
  top: 0;
  right: 20px;
}
.icon-t1 {
  background: url(../assets/images/global_icon/202405-7.png) 10px center no-repeat;
  background-size: 18px;
}
.icon-t2 {
  background: url(../assets/images/global_icon/202405-8.png) 10px center no-repeat;
  background-size: 18px;
}
.icon-t3 {
  background: url(../assets/images/global_icon/202405-9.png) 10px center no-repeat;
  background-size: 18px;
}
.icon-t4 {
  background: url(../assets/images/global_icon/202405-10.png) 10px center no-repeat;
  background-size: 18px;
}
.icon-t5 {
  background: url(../assets/images/global_icon/202405-11.png) 10px center no-repeat;
  background-size: 18px;
}
.icon-t6 {
  background: url(../assets/images/global_icon/202405-12.png) 10px center no-repeat;
  background-size: 18px;
}
.icon-t7 {
  background: url(../assets/images/global_icon/202405-13.png) 10px center no-repeat;
  background-size: 18px;
}
.icon-t9 {
  background: url(../assets/images/global_icon/202405-14.png) 10px center no-repeat;
  background-size: 18px;
}
.icon-t10 {
  background: url(../assets/images/global_icon/202405-15.png) 10px center no-repeat;
  background-size: 18px;
}
.icon-t11 {
  background: url(../assets/images/global_icon/202405-16.png) 10px center no-repeat;
  background-size: 18px;
}
.icon-t12 {
  background: url(../assets/images/global_icon/202405-17.png) 10px center no-repeat;
  background-size: 18px;
}
.icon-t13 {
  background: url(../assets/images/global_icon/202405-18.png) 10px center no-repeat;
  background-size: 18px;
}


.my-info-b { color: #624923;  display: flex; line-height: 22px; height: 60px; margin: 0 6px;  }

.my-info-b .t{ width: calc(33.3% - 6px); border-radius: 10px; background-size: 40px auto !important; padding-left: 55px; margin: 0 3px;
   background: #1c2636 url(../assets/images/global_icon/202405-20.png) 5px center no-repeat; background-size: 46px auto !important; } 
.my-info-b .t.mr5{background: #1c2636 url(../assets/images/global_icon/202405-21.png) 5px center no-repeat; background-size: 46px auto !important; }

.my-info-b .t.ls{background: #1c2636 url(../assets/images/global_icon/202405-21b.png) 5px center no-repeat; background-size: 46px auto !important; }

.my-info-b h5{ margin: 0; padding: 0; color: #fff; line-height: 16px; padding-top: 13px; margin-left: 2px; font-size: 12px;}
.my-info-b h5 strong{ color: #00eda6;font-size: 14px;}
.my-info-b span{ color: #b6bdcc; opacity: .8;line-height: 18px;margin-left: 2px; font-size: 10px; }
.my-info-b span i{ vertical-align: sub;}



.my-info-b .fc-FFC700{font-size: 18px;vertical-align: top;}
.my-info-b .nut-button--small{font-size: 14px !important;}
.my-info-b .nut-button--primary { background: linear-gradient(135deg,#ff9e0d 0%,#ffa70d 45%,#ffb60d 83%,#ffbe0d 100%) !important; min-width:70px !important;padding: 0px 10px !important;}
.my-info-b .nut-button--info {min-width:70px !important;padding: 0px 10px !important;}
.my-info-b .mr10{ padding:5px 0 5px 30px;background: url(../assets/images/global/bg194.png) 5px 3px no-repeat;background-size: 20px auto;}






.nut-tabs__titles.normal .nut-tabs__titles-item {
  font-size: 12px;
}
.vip-popup h3 {
  font-size: 15px;
  padding: 10px;
}
.vip-popup table {
  width: 100%;
}
.vip-popup table th {
  border: 1px #ccc solid;
  background: #f4f1ff;
  line-height: 28px;
  font-weight: 400;
}
.vip-popup table td {
  border: 1px #ccc solid;
  line-height: 18px;
  text-align: center;
}
.vip-popup dl {
  padding: 10px;
}
.vip-popup dt {
  font-size: 14px;
  line-height: 24px;
  color: #555;
}
.vip-popup dd {
  font-size: 12px;
  color: #999;
}

.my-info {background:#000; background-size: 100% auto;  margin-top: 10px; margin-bottom: 10px; margin-top: 55px;}
.my-info .my-info-t{ display: flex; padding-top: 8px;}

.my-info ul { padding: 8px 0; margin-bottom: 5px; width: calc(100% - 80px);}
.my-info ul li { line-height: 22px; height: 22px; color: #fff;}
.my-info ul li label { color: #ab94ff; margin-right: 2px;  width: 85px; float: left;}

.my-info-c{ display: flex; text-align: left; margin: 0px 10px 10px 10px; padding-top: 8px; border-radius: 0 0 10px 10px; background: linear-gradient(1turn, #104a39, #228769);}

.my-info-c .h5{ margin: 0; padding: 0; color: #fff; line-height: 24px; height: 62px; margin-bottom: 6px; font-size: 20px; position: relative; z-index: 105;}
.my-info-c span{ color: #fff; opacity: 1;line-height: 30px; font-size: 14px; margin-left: 0px; display: block;}

.my-info-c .h5 strong{ font-size: 20px; line-height: 30px; color: #ffe114;}

.my-info-c .t{ width: calc(50% - 30px); padding-left: 15px; margin-left: 10px; position: relative; z-index: 100; min-height: 70px; border-radius: 10px;}
.my-info-c .t.b1{ min-height: 100px;}
.my-info-c .t.b1 .p-text{ color: #fff; font-size: 12px; margin: 0; padding: 0; line-height: 16px;}
.my-info-c .t.b1 .p-text p{ margin: 0; margin-top: 3px; font-size: 10px; line-height: 12px; color: #c4ffae;}
.my-info-c .t.b1 .p-text strong{ line-height: 22px;}
.my-info-c .btn-box{ height: 20px; overflow: hidden; display: flex;}
.my-info-c .btn-t{ margin-right: 5px; border: 0; background: #ffe114; color: #392500;  border-radius: 10px;font-size: 10px; 
  padding: 0px 8px; line-height: 20px;  height: 20px;overflow: hidden; display: inline-block; }

.m8 .nut-cell{padding: 12px 5px 12px 16px; color: #fff;}


</style>
<style>
.my-info-c .nut-circleprogress{ margin-left: 10px; margin-top: -3px;}
.my-info-c .nut-circleprogress-path{stroke: #002b1e !important;}
.my-info-c .nut-circleprogress-text{ color: #fff; font-weight: 600;}

.my-info-c .nut-progress .nut-progress-outer{background-color: #002b1e !important;}

.m8 .nut-cell-group__warp{background-color: #1a2235 !important;  background: linear-gradient(135deg, #1c2636 0%, #11293b 51%) !important; border-radius: 20px; padding: 12px 5px;}
.m8 .nut-cell-group__warp .nut-cell:last-child:after{border-bottom: 0px !important }
.m8 .nut-cell-group .nut-cell:after {border-bottom: 1px solid #344b72 !important;}
.m8 .nut-cell-group .nut-cell__title {margin-left: 25px !important;}
.m8 .nut-cell__link{ color: #11263b;}
.nut-actionsheet-panel .nut-actionsheet-menu{padding-bottom: 75px !important}
.nut-popup .nut-dialog{padding: 28px 14px 16px !important;}

.icon-t3 .nut-cell__value{color: #00eda6; font-weight: 600;}
.icon-t1 .nut-cell__value{background: #00eda6 url(../assets/images/global_icon/202405-24.png) 5px center no-repeat; background-size: 18px; 
  color: #000; padding: 0px 10px 0 30px; border-radius:10px;}
 .login-bd .icon-t1 .nut-cell__value{background: #fff}
</style>
