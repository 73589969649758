<template>
  <div class="index gameLobby" >
    <div class="inx-top">
      <div class="c">
          <div class="l">
            <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick" style="width: 40px;height: 40px;line-height: 40px;"></i>
          </div>
          <div class="r"></div>
        </div>
    </div>

    <div class="index-main games-list" style="padding-top: 50px;">

      
      <div class="lobby-tab x3">

        <nut-tabs v-model="tabValue" >

          <nut-tabpane title="Originals">
            <div class="jili-3x link-3x" >
              <div  v-for="(item, index) in lobbyGames" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)">
                <img v-if="item.gameId === 'crash2'" :src="require('../assets/images/global/gm1.png')" />
                <img v-if="item.gameId === 'parity'" :src="require('../assets/images/global/gm3.png')" />
                <img v-if="item.gameId === 'mine'" :src="require('../assets/images/global/gm2.png')" />
                <img v-if="item.gameId === 'wheel'" :src="require('../assets/images/global/gm4.png')" />
                <img v-if="item.gameId === 'andar'" :src="require('../assets/images/global/gm5.png')"/>
                <img v-if="item.gameId === 'dice'"  :src="require('../assets/images/global/gm7.png')"/>
              </div>
            </div>
          </nut-tabpane>

          <nut-tabpane title="Slots" >
            <div class="jili-3x link-3x infiniteUl"  id="scrollSlots" >
              <nut-infiniteloading
                  containerId = 'scrollSlots'
                  :use-window='false'
                  :has-more="hasMoreSlots"
                  :load-txt="loadText"
                  load-more-txt="No more!"
                  load-icon=""
                  @load-more="loadMoreSlots"
                >
                <div v-for="(item, index) in gamesSlotsList" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)">
                  <img  @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
                  <p class="name">{{item.name}}</p>
                </div>
              </nut-infiniteloading>
            </div>
          </nut-tabpane>

          <nut-tabpane title="Poker">
            <div class="jili-3x link-3x infiniteUl"  id="scrollPoker">
              <nut-infiniteloading
                  containerId= 'scrollPoker'
                  :use-window='false'
                  :has-more="hasMorePoker"
                  :load-txt="loadText"
                  load-more-txt="No more!"
                  load-icon=""
                  @load-more="loadMorePoker"
                >

              <div v-for="(item, index) in gamesPokerList" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)">
                <img  @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
                <p class="name">{{item.name}}</p>
              </div>
              </nut-infiniteloading>
            </div>
          </nut-tabpane>


          <nut-tabpane  title="Casino" >
            <div class="jili-3x link-3x infiniteUl"  id="scrollCasino">
              <nut-infiniteloading
                  containerId= 'scrollCasino'
                  :use-window='false'
                  :has-more="hasMoreCasino"
                  :load-txt="loadText"
                  load-more-txt="No more!"
                  load-icon=""
                  @load-more="loadMoreCasino"
                >
              <div v-for="(item, index) in gamesCasinoList" :key="index"  class="link-i"  @click="toGameHome(item.gameId, item.platform, item.name)">
                <img  @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
                <p class="name">{{item.name}}</p>
              </div>
              </nut-infiniteloading>
            </div>
          </nut-tabpane>

          <nut-tabpane title="Fishing">
            <div class="jili-3x link-3x link-3x infiniteUl"  id="scrollFish">
              <nut-infiniteloading
                  containerId= 'scrollFish'
                  :use-window='false'
                  :has-more="hasMoreFish"
                  :load-txt="loadText"
                  load-more-txt="No more!"
                  load-icon=""
                  @load-more="loadMoreFish"
                >
              <div v-for="(item, index) in gamesFishList" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)">
                <img  @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
                <p class="name">{{item.name}}</p>
              </div>
              </nut-infiniteloading>
            </div>
          </nut-tabpane>

          

        </nut-tabs>

      </div>
      
      </div>




  </div>
  <tabbar :activeIndex="tabbarActiveIndex"></tabbar>


</template>

<script>
import {ref, reactive, toRefs, onMounted, getCurrentInstance} from "vue";
import {useRouter,useRoute} from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import { Toast } from "@nutui/nutui";
import axios from "axios";

export default {
  name: "gamesList",
  components: {
    tabbar: TabbarMain,
  },
  data() {
    return {
      errorImg: 'this.src="' + require('../assets/images/global/error-img.png') + '"',
      i18n:{
        loading: this.$t('global.loading'),
      }
    };
  },
  methods: {
    getImgUrl(platform,gameId){
      try {
          if(platform === 'cq9'){
            return require('@/assets/images/lobby_icon/'+ platform + '/280_280_EN/280x280_EN_GAMEID_' + gameId + '.png')
          }else if(platform === 'jili'){
            return require('@/assets/images/lobby_icon/'+ platform + '/260_380_EN/260x380_EN_GAMEID_' + gameId + '.png')
          }else{
            // lobby yw类型的时候 返回jili目录下的图标
            return require('@/assets/images/lobby_icon/jili/260_380_EN/260x380_EN_GAMEID_' + gameId + '.png')
          }
        } catch (error) {
          return require('../assets/images/global/error-img.png')
        }
    },
  },
  setup() {
    const _data = getCurrentInstance();
    

    const state = reactive({
      loadText: 'Loading...',
      tabValue: '0',
      tabType:'all',
      lobbyGames: [],

      gamesSlotsList: [],
      gamesPokerList: [],
      gamesCasinoList: [],
      gamesFishList: [],

      gamesSlotsData: [],
      gamesPokerData: [],
      gamesCasinoData: [],
      gamesFishData: [],

      gamesSlotsPage: 1,
      gamesPokerPage: 1,
      gamesCasinoPage: 1,
      gamesFishPage: 1,

      tabbarActiveIndex: null,
      loadingCompleted: false,
      pageSize: 24
      
    });
    const router = useRouter();
    const route  = useRoute();

    const hasMoreSlots = ref(true);
    const hasMorePoker = ref(true);
    const hasMoreCasino = ref(true);
    const hasMoreFish = ref(true);

    const loadMoreSlots = done => {
        setTimeout(() => {
          for (let i = 0; i < state.pageSize; i++) {
            if(state.gamesSlotsData[i]){
              state.gamesSlotsList.push(state.gamesSlotsData[i]);
            }else{ return }
          }
          state.gamesSlotsData = state.gamesSlotsData.splice(state.pageSize, state.gamesSlotsData.length)
          state.loadText = state.gamesSlotsData.length < state.pageSize ? 'No more!' : 'Loading...'
          done()
        }, 500);
    };
    const loadMorePoker = done => {
        setTimeout(() => {
          for (let i = 0; i < state.pageSize; i++) {
            if(state.gamesPokerData[i]){
              state.gamesPokerList.push(state.gamesPokerData[i]);
            }else{ return }
          }
          state.gamesPokerData = state.gamesPokerData.splice(state.pageSize, state.gamesPokerData.length)
          state.loadText = state.gamesPokerData.length < state.pageSize ? 'No more!' : 'Loading...'
          done()
        }, 500);
    };
    const loadMoreCasino = done => {
        setTimeout(() => {
          for (let i = 0; i < state.pageSize; i++) {
            if(state.gamesCasinoData[i]){
              state.gamesCasinoList.push(state.gamesCasinoData[i]);
            }else{ return }
          }
          state.gamesCasinoData = state.gamesCasinoData.splice(state.pageSize, state.gamesCasinoData.length)
          state.loadText = state.gamesCasinoData.length < state.pageSize ? 'No more!' : 'Loading...'
          done()
        }, 500);
    };
    const loadMoreFish = done => {
        setTimeout(() => {
          for (let i = 0; i < state.pageSize; i++) {
            if(state.gamesFishData[i]){
              state.gamesFishList.push(state.gamesFishData[i]);
            }else{ return }
          }
          state.gamesFishData = state.gamesFishData.splice(state.pageSize, state.gamesFishData.length)
          state.loadText = state.gamesFishData.length < state.pageSize ? 'No more!' : 'Loading...'
          done()
        }, 500);
    };
    const methods = {

      toGameHome(id, platform, name){
        let _id = id
        let _name = name
        let _platform = platform
        if(platform === 'lobby'){
          router.push({ path: '/' + _id });
        }else{
          router.push({
            path: 'playGame',
            query: {
              id: _id,
              name: _name,
              platform: _platform
            }
          })
        }
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      backClick() {
        router.push({path: "lobby"});
      },
    };
    onMounted(() => {
      if(route.query.tabType){
        state.tabType = route.query.tabType;
      }
      switch (state.tabType) {
        case 'all':
          state.tabValue = '0'
          break;
        case 'slots':
          state.tabValue = '1'
          break;
        case 'poker':
          state.tabValue = '2'
          break;
        case 'casino':
          state.tabValue = '3'
          break;
        case 'fishing':
          state.tabValue = '4'
          break;
      }
      methods.textToast(_data.data.i18n.loading);
      axios
        .get("/api/game/lobby", {})
        .then(function (res) {
          for (const key in res.data.data.games) {
            if (res.data.data.games[key].platform === 'lobby') {
              state.lobbyGames.push(res.data.data.games[key])

            }else if(res.data.data.games[key].platform === 'jili' || res.data.data.games[key].platform === 'cq9'){
              if(res.data.data.games[key].type === 'slots'){
                state.gamesSlotsData.push(res.data.data.games[key])
              }else if(res.data.data.games[key].type === 'fish'){
                state.gamesFishData.push(res.data.data.games[key])
              }else if(res.data.data.games[key].type === 'poker'){
                state.gamesPokerData.push(res.data.data.games[key])
              }else if(res.data.data.games[key].type === 'casino'){
                state.gamesCasinoData.push(res.data.data.games[key])
              }
            }
          }
          state.gamesSlotsList = state.gamesSlotsData.slice(0,state.pageSize)
          state.gamesSlotsData = state.gamesSlotsData.splice(state.pageSize, state.gamesSlotsData.length)
          state.gamesPokerList = state.gamesPokerData.slice(0,state.pageSize)
          state.gamesPokerData = state.gamesPokerData.splice(state.pageSize, state.gamesPokerData.length)
          state.gamesCasinoList = state.gamesCasinoData.slice(0,state.pageSize)
          state.gamesCasinoData = state.gamesCasinoData.splice(state.pageSize, state.gamesCasinoData.length)
          state.gamesFishList = state.gamesFishData.slice(0,state.pageSize)
          state.gamesFishData = state.gamesFishData.splice(state.pageSize, state.gamesFishData.length)
          // switch (state.tabType) {
          //   case 'slots':
          //     state.gamesSlotsList = state.gamesSlotsData.slice(0,state.pageSize)
          //     state.gamesSlotsData = state.gamesSlotsData.splice(state.pageSize, state.gamesSlotsData.length)
          //     break;
          //   case 'poker':
          //     state.gamesPokerList = state.gamesPokerData.slice(0,state.pageSize)
          //     state.gamesPokerData = state.gamesPokerData.splice(state.pageSize, state.gamesPokerData.length)
          //     break;
          //   case 'casino':
          //     state.gamesCasinoList = state.gamesCasinoData.slice(0,state.pageSize)
          //     state.gamesCasinoData = state.gamesCasinoData.splice(state.pageSize, state.gamesCasinoData.length)
          //     break;
          //   case 'fishing':
          //     state.gamesFishList = state.gamesFishData.slice(0,state.pageSize)
          //     state.gamesFishData = state.gamesFishData.splice(state.pageSize, state.gamesFishData.length)
          //     break;
          // }
          Toast.hide("loading");
          state.loadingCompleted = true 
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
    });
    return {
      hasMoreSlots,
      hasMorePoker,
      hasMoreCasino,
      hasMoreFish,
      loadMoreSlots,
      loadMorePoker,
      loadMoreCasino,
      loadMoreFish,
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.index{ min-width: 340px; margin: 0 auto; background:#110E1B; padding-bottom: 50px;}
.inx-top { position: fixed;top: 0; left: 0;right: 0;height: 40px;z-index: 2000;
  background:linear-gradient(135deg, #1c2636 0%, #1a2235 51%) !important;box-shadow: 0 1px 7px #170126;}
.inx-top .l { width: 45%;float: left; line-height: 40px; height: 40px; color: #fff; font-size: 14px;}
.inx-top .r {width: 45%; float: right;line-height: 40px; height: 40px; text-align: right;}
.inx-top .r i { width: 16px;height: 16px;line-height: 18px;border: 2px solid #fff; display: inline-block; font-weight: 600;
  border-radius: 20px;font-size: 16px;color: #fff;text-align: center;font-style: normal; margin: 3px 10px 0 0;opacity: .7;}
.link-3x{ display: flex; justify-content: center; flex-wrap: wrap; justify-content: flex-start; margin: 20px 0 0 0; width: 100%;}
.link-3x .link-i{ width: 33.3%; position: relative;margin:0; background: url(../assets/images/global/260x380_bg.png) center top no-repeat; background-size: 100% auto;}
.link-3x .link-i img{ width: 100%;}
.inx-bn { padding: 40px 0 0 0; margin: 0; width: 100%; position: relative; overflow: hidden;}
.inx-bn img { width: calc(100% - 0px); height: auto; }

.infiniteUl {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
}


</style>
<style>
.infiniteUl .nut-infinite-container{display: flex;flex-wrap: wrap; justify-content: flex-start;}

.infiniteUl .nutui-iconfont.nut-icon.bottom-img{ display: none;}
.infiniteUl .nut-infiniteloading .nut-infinite-bottom{ padding-top: 0; padding-bottom: 10px;}
</style>
