<template>
  <div>
    <nut-navbar
    :left-show="false"
      :title="$t('userRegister.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
      <template #left>
        <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
      </template>
    </nut-navbar>
  
    <div class="login userLogin">
  
      <div class="login-bn s2">
        <h1>WINZONE789</h1>

        <p class="bg" v-if="state.registerReward > 0"></p>
        <p v-else>100% Secure & Real</p>
      </div>
  
      <div class="login-bd">
        <nut-form>
        <div class="login-inp">
          <label class="fs12 fc-999">{{ $t('userLogin.mobileNumber')}}</label>
          <p class="err-info" v-show="errText.mobile">{{errText.mobile}}</p>
          <p class="err-info" v-show="state.mobileErrText">{{state.mobileErrText}}</p>
          
          <div class="nation">+91</div>
  
          <login-input
            label=""
            :placeholder="$t('userLogin.mobileNumberPlaceholder')"
            type="number"
            rule="^.{1,18}$"
            class="pl40"
            autocomplete="off"
            @inputChange="(res) => (registerInfo.mobile = res)"
            @blur="inpBlur(registerInfo.mobile)"
            v-model="registerInfo.mobile"
          />
        </div>
  
  
        <div class="login-inp" v-if="commonInfo.checkAuthCode">
          <p class="fs10 fc-999">{{ $t('userRegister.verificationCode') }}</p>
          <p class="err-info" v-show="errText.authCode">{{errText.authCode}}</p>
          <div class="nut-input-inner w100">
            <login-input
              label=""
              :placeholder="$t('userRegister.verificationCodePlaceholder')"
              type="number"
              rule="^\d{6}$"
              maxLength="9"
              autocomplete="off"
              style="margin-right: 10px;"
              @inputChange="(res) => (registerInfo.authCode = res)"
            />
            <nut-button 
              type="info"
              size="mini"
              v-show="state.sendAuthCode"
              @click="getCode(registerInfo.mobile)"
              >{{ $t('userRegister.OTP') }}</nut-button
            >
            <nut-button
              type="info"
              size="mini"
              v-show="!state.sendAuthCode"
              :disabled="true"
              >{{ state.second }} s</nut-button
            >
          </div>
        </div>
  
  
        
        <div class="login-inp">
          <label class="fs12 fc-999">{{ $t('userLogin.password')}}</label>
          <p class="err-info" v-show="errText.password">{{errText.password}}</p>
          <login-input
            label=""
            :placeholder="$t('userLogin.passwordPlaceholder')"
            :type="passwordShow ? 'text' : 'password'"
            rule="^.{1,32}$"
            maxLength="32"
            autocomplete="off"
            @inputChange="(res) => (registerInfo.password = res)"
            @blur="verifyPassword"
          />
          <div class="inp-r" @click="passwordShow = !passwordShow">
            <nut-icon name="eye" v-if="passwordShow"></nut-icon>
            <nut-icon name="marshalling" v-else></nut-icon>
          </div>
        </div>
  
        <div class="login-inp">
          <label class="fs12 fc-999">{{ $t('userRegister.confirmPassword') }}</label>
          <p class="err-info" v-show="errText.confirmPassword">{{errText.confirmPassword}}</p>
          <login-input
            label=""
            :placeholder="$t('userRegister.confirmPasswordPlaceholder')"
            :type="passwordShow ? 'text' : 'password'"
            rule="^.{1,32}$"
            maxLength="32"
            autocomplete="off"
            @inputChange="(res) => (registerInfo.confirmPassword = res)"
            @blur="verifyPassword"
          />
        </div>
  
        <div class="login-inp mb10" v-show="inviteCodeShow">
          <label class="fs12 fc-999">{{ $t('userRegister.recCode') }}</label>
          <p class="err-info" v-show="errText.inviteCode">{{errText.inviteCode}}</p>
          <login-input
            label=""
            :placeholder="$t('userRegister.recCodePlaceholder')"
            type="text"
            maxLength="10"
            :value="registerInfo.inviteCode"
            autocomplete="off"
            @inputChange="(res) => (registerInfo.inviteCode = res)"
          />
        </div>
  
        <form>
          <div class="login-checkbox">
            <nut-checkbox
              v-model="state.checkbox1"
              icon-size="14"
              >{{ $t('userRegister.iAgree') }}
              <span @click="state.showBasic = true"
                >{{ $t('userRegister.privacyPolicy') }}</span
              ></nut-checkbox
            >
          </div>
        </form>
  
        <div class="login-btn">
          <nut-button block type="info" @click="registerSubmit"
            >{{ $t('userRegister.registerBtn') }}</nut-button>
          <p class="f-c-login">
            <span>{{ $t('userLogin.signIn_text')}}</span> 
            <strong @click="userLoginClick">{{ $t('userLogin.signIn_btn')}}</strong>
          </p>
        </div>
        </nut-form>
      </div>
  
  
      <nut-popup
        pop-class="popclass popclass-pp"
        :style="{ padding: '10px 20px',width: '300px', zIndex: '1000',  background:'#1a2235' }"
        v-model:visible="state.showBasic"
        :z-index="100"
        :closeable="true"
      >
        <privacyPolicy></privacyPolicy>
      </nut-popup>
  

      <nut-popup
        position="top"
        :z-index="100"
        :style="{ height: '300px'}"
        @closed="topPopclose" 
        v-model:visible="taskPop">
        <div class="pop-top-userReg">
          <h4>{{ $t('userRegister.success') }}</h4>
          <p v-if="state.registerReward > 0">
            {{ $t('userRegister.signUpBonus') }}<br/>
            <strong>{{ $filters.currencySymbol(state.registerReward) }}</strong>
          </p>
        </div>
      </nut-popup>

    </div>
    <img style="width:100%;" :src="require('../assets/images/global/bg145.jpg')"/>
  </div>
  </template>
  
  <script>
  import { reactive, onMounted, getCurrentInstance } from "vue";
  import { useRouter } from "vue-router";
  import { Toast } from "@nutui/nutui";
  import axios from "axios";
  import loginInput from "@/components/loginInput.vue";
  import PrivacyPolicy from "../components/PrivacyPolicy.vue";
  import { isNotBlank, delSpace } from "@/assets/js/commonUtil";
  import {getLastTimeGamePlatform, getLastTimeGameName} from "@/assets/js/environment";
  import { eventTrackingRegister} from "@/assets/js/eventTracking";
  export default {
    components: {
      privacyPolicy: PrivacyPolicy,
      loginInput: loginInput,
    },
    data() {
      return {
        off:'off',
        country: '',
        commonInfo: null,
        lastGameName: '',
        passwordShow: false,
        serviceEmail:'',
        registerInfo:{
          mobile: "",
          authCode: "",
          inviteCode: "",
          password:"",
          confirmPassword:"",
          sourceAppType: null,
          sourceUrl: null,
          gameId: "",
          platform: "",
        },
        errText:{
          mobile: "",
          authCode: "",
          inviteCode: "",
          password:"",
          confirmPassword: null
        },
        shareBonusCode: "",
        inviteCodeShow : true,
        confirmPassword : false,
        taskPop: false,
        i18n:{
          successfully: this.$t('global.successfully'),
          errorfully: this.$t('global.errorfully'),
          mobileError: this.$t('global.INVALID_INPUT_VALUE'),
        }
      }
    },
    created() {
      this.commonInfo = JSON.parse(localStorage.getItem("commonInfo"))
      this.lastGameName = getLastTimeGameName()
      if(isNotBlank(this.lastGameName)){
        this.registerInfo.gameId = this.lastGameName
        this.registerInfo.platform = getLastTimeGamePlatform()
      } 
    },
    methods: {
      verifyPassword(){
        if(this.registerInfo.password != '' && this.registerInfo.confirmPassword != '' && this.registerInfo.password != this.registerInfo.confirmPassword){
          this.errText.confirmPassword = this.$t('global.passwordsError')
          this.confirmPassword = false
        }else{
          this.errText.confirmPassword = null
          this.confirmPassword = true
        }
      },
      registerSubmit() {
        this.errText.mobile = ""
        this.errText.authCode = ""
        this.errText.password = ""
        this.errText.confirmPassword = null
        if(!this.commonInfo.checkAuthCode) {
          this.registerInfo.authCode = '000000'
        }
        if(localStorage.getItem("firstVisit")) {
          this.registerInfo.sourceAppType = localStorage.getItem("firstVisit")
        }
        if (!this.state.checkbox1) {
          this.failToast(this.$t('global.agreeError'));
          return;
        }
        if(this.registerInfo.mobile === ""){
          this.errText.mobile = this.$t('global.errInfoRequired')
          return
        }
        if(this.registerInfo.authCode === ""){
          this.errText.authCode = this.$t('global.errInfoRequired')
          return
        }
        if(this.registerInfo.password === ""){
          this.errText.password = this.$t('global.errInfoRequired')
          return
        }
        if(this.registerInfo.confirmPassword === ""){
          this.errText.confirmPassword = this.$t('global.errInfoRequired')
          return
        }
        if(!this.confirmPassword){
          this.failToast(this.$t('global.passwordsError'));
          this.errText.confirmPassword = this.$t('global.passwordsError')
          return
        }else{
          Toast.loading(this.$t('global.loading'), {
            id: "loading",
            duration: 0,
            bgColor: "rgba(0, 0, 0, 0.9)",
            cover: true,
          });
          if(this.registerInfo.inviteCode === ""){
            this.registerInfo.inviteCode = null
          }
          this.registerInfo.mobile = delSpace(this.registerInfo.mobile)
          this.registerInfo.authCode = delSpace(this.registerInfo.authCode)
          this.registerInfo.password = delSpace(this.registerInfo.password)
          //来源
          if(localStorage.getItem("SOURCE_REFERRER")){
            this.registerInfo.sourceUrl = localStorage.getItem("SOURCE_REFERRER")
          }
          
          //红包注册
          //if (this.shareBonusCode && parseInt(localStorage.getItem("needVip")) === 0) {
          if (this.shareBonusCode) {
            axios
            .post("/api/user/share-bonus/register", {
              mobile: this.registerInfo.mobile,
              authCode: this.registerInfo.authCode,
              password: this.registerInfo.password,
              shareBonusCode: this.shareBonusCode,
              sourceAppType: this.registerInfo.sourceAppType,
              registerHost: window.location.host,
              sourceUrl: this.registerInfo.sourceUrl,
              gameId: this.registerInfo.gameId,
              platform: this.registerInfo.platform,
            })
            .then((response)=> {
              if(response.data.code === 0){
                //埋点
                eventTrackingRegister(response.data.data.inviteCode, response.data.data.invokeFb);
                localStorage.setItem("token", response.data.data.token);
                localStorage.setItem("shareBonusReceiveCode", response.data.data.shareBonusReceiveCode)
                localStorage.setItem("regStoreCoin", 1);
                this.taskPop = true;

                if(response.data.data.receiveSuccess){
                  setTimeout(() => {
                      this.$router.push("/shareBonus");
                      this.taskPop = false;
                  }, 1500);
                }else{
                  setTimeout(() => {
                    this.taskPop = false;
                    //this.$router.push('/?c='+ response.data.data.shareBonusReceiveCode);
                    window.location.href = this.commonInfo.frontBaseUrl + '?c='+ response.data.data.shareBonusReceiveCode
                  }, 1500);
                }

              }else if (response.data.code === 1004){
                let res = response.data.data
                for(var i in res){	
                  switch (res[i].fieldName) {
                    case "mobile":
                      this.errText.mobile = res[i].description
                      break;
                    case "authCode":
                      this.errText.authCode = res[i].description
                      break;
                    case "password":
                      this.errText.password = res[i].description
                      break;
                    case "shareBonusCode":
                      this.failToast(res[i].description);
                      break;
                  }
                }
              }
              Toast.hide("loading");
            })
            .catch(function (error) {
              console.log(error);
              Toast.hide("loading");
            }); 

          //正常注册      
          }else{
            axios
            .post("/api/user/register", {
              mobile: this.registerInfo.mobile,
              authCode: this.registerInfo.authCode,
              password: this.registerInfo.password,
              inviteCode: this.registerInfo.inviteCode,
              sourceAppType: this.registerInfo.sourceAppType,
              registerHost: window.location.host,
              sourceUrl: this.registerInfo.sourceUrl,
              gameId: this.registerInfo.gameId,
              platform: this.registerInfo.platform,
            })
            .then((response)=> {
              if(response.data.code === 0){
                //埋点
                eventTrackingRegister(response.data.data.inviteCode, response.data.data.invokeFb);
                localStorage.setItem("token", response.data.data.token);
                this.taskPop = true;
                axios({
                  url: "/api/system/common",
                  method: "get",
                }).then((res) => {
                  localStorage.setItem("commonInfo",JSON.stringify(res.data.data));
                  localStorage.setItem("regStoreCoin", 1);
                  setTimeout(() => {
                    if(parseInt(localStorage.getItem("needVip")) > 0){
                      window.location.href = "receiveBonus?c=" + localStorage.getItem("shareBonusCode")
                    }else if(this.commonInfo.pureMode){
                      //this.$router.push({ path: '/firstRechargeBonus' })
                      window.location.href = "/firstRechargeBonus"
                    }else{
                      //this.$router.push({ path: '/'+ this.lastGameName });
                      this.$router.push({ path: '/lobby'});
                    }
                    this.taskPop = false;
                  }, 1500);
                })
              }else if (response.data.code === 1004){
                let res = response.data.data
                for(var i in res){	
                  switch (res[i].fieldName) {
                    case "mobile":
                      this.errText.mobile = res[i].description
                      break;
                    case "authCode":
                      this.errText.authCode = res[i].description
                      break;
                    case "password":
                      this.errText.password = res[i].description
                      break;
                    case "shareBonusCode":
                      this.failToast(res[i].description);
                      break;
                  }
                }
              }else{
                  if(response.data.msg != undefined){
                      Toast.fail(response.data.msg);
                  }
              }
              Toast.hide("loading");
            })
            .catch(function (error) {
              console.log(error);
              Toast.hide("loading");
            });
          }
        }
      },
  
    },
    beforeMount() {
      if(localStorage.getItem("invite")){
        this.registerInfo.inviteCode = localStorage.getItem("invite")
        localStorage.removeItem("shareBonusCode");
      }
      if(localStorage.getItem("shareBonusCode")){
        this.shareBonusCode = localStorage.getItem("shareBonusCode")
        localStorage.removeItem("invite");
        this.inviteCodeShow = false
      }
    },
    setup() {
      const _data = getCurrentInstance();
      const state = reactive({
        runningDays: '',
        serviceEmail: '',
        businessEmail: '',
        registerReward: '',
        currencySymbol: '',
        checkbox1: true,
        showBasic: false,
        sendAuthCode: true /* 通过v-show控制显示‘获取按钮’还是‘倒计时’ */,
        second: 60 /* 一分钟 倒计时*/,
        timer: null /* 倒计时 计数器,防止点击的时候触发多个setInterval*/,
        mobileErrText: '',
        firstRechargeGift: 0,
      });
  
      const getCode = (m) => {
        let mobile = m;
        state.mobileErrText = ''
        if (mobile != "") {
          axios
            .post("/api/system/sms/send", {
              mobile: mobile,
              type: "reg_code",
            })
            .then(function (reg) {
              if(reg.data.code === 0){
                //Toast.success(_data.data.i18n.successfully);
                Toast.success(_data.data.i18n.successfully, {
                  duration: 4000
                });
                if (state.sendAuthCode) {
                    state.sendAuthCode = false;
                    let interval = window.setInterval(function () {
                    setStorage(state.second);
                    if (state.second-- <= 0) {
                        //如果 倒计时完毕 重新赋值
                        state.second = 60;
                        state.sendAuthCode = true;
                        window.clearInterval(interval);
                    }
                    }, 1000);
                }
              } else if (reg.data.code === 1004){
                let res = reg.data.data
                Toast.fail(_data.data.i18n.mobileError);
                for(var i in res){	
                  switch (res[i].fieldName) {
                    case "mobile":
                      state.mobileErrText = res[i].description
                      break;
                  }
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          state.mobileErrText = _data.data.i18n.mobileError
        }
      };
      /**
       * 存储 验证码 防止刷新
       * `用处`: 防止页面刷新 发送验证码状态改变
       * */
      const setStorage = (parm) => {
        localStorage.setItem("dalay", parm);
        localStorage.setItem("_time", new Date().getTime());
      };
      /**
       * 获取 缓存 数据
       * `用处`: 防止页面刷新 发送验证码状态改变
       * */
      const getStorage = () => {
        let localDelay = {};
        localDelay.delay = localStorage.getItem("dalay");
        localDelay.sec = localStorage.getItem("_time");
        return localDelay;
      };
      /**
       *  判断
       *  */
      const judgeCode = () => {
        // 获取缓存中的数据
        let localDelay = getStorage();
        let secTime = parseInt((new Date().getTime() - localDelay.sec) / 1000);
        if (secTime > localDelay.delay) {
          state.sendAuthCode = true;
        } else {
          state.sendAuthCode = false;
          let _delay = localDelay.delay - secTime;
          state.second = _delay;
          state.timer = setInterval(() => {
            if (_delay > 1) {
              _delay--;
              setStorage(_delay);
              state.second = _delay;
              state.sendAuthCode = false;
            } else {
              // 让浏览器打开的时候,显示剩余的时间
              state.sendAuthCode = true;
              window.clearInterval(state.timer);
            }
          }, 1000);
        }
      };
      judgeCode();
      const successToast = (msg) => {
        Toast.success(msg);
      };
      const failToast = (msg) => {
        Toast.fail(msg);
      };
      const router = useRouter();
      const methods = {
        backClick() {
          //router.go(-1);
          router.push({
            name: "gameLobby",
          });
        },
      };
      const userLoginClick = () => {
        router.push({
          name: "userLogin",
        });
      };
      onMounted(() => {
        axios.get("/api/system/common", {}).then((response)=> {
          state.runningDays = response.data.data.runningDays
          state.registerReward = response.data.data.registerReward
          state.currencySymbol = response.data.data.currencySymbol
          state.serviceEmail = response.data.data.serviceEmail
          state.businessEmail = response.data.data.businessEmail
          state.firstRechargeGift = response.data.data.firstRechargeGift
          
        })
      })
      return {
        router,
        failToast,
        successToast,
        getCode,
        userLoginClick,
        state,
        ...methods,
      };
    },
  };
  </script>
  
  <style scoped>
  </style>
