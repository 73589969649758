//import { createI18n } from 'vue-i18n'
import {isBlank, isNotBlank} from "@/assets/js/commonUtil";

/**
 * 是否debug模式
 * @returns {string}
 */
export function getDebugMode() {
    const debugMode = document.querySelector('body').getAttribute('dm');
    if (debugMode === 'DEBUG_MODE') {
        return 'true';
    }
    return debugMode;
}
/**
 * 获取国家
 *
 * 印度    in
 * @returns {string}
 */
export function getCountry() {
    const country = document.querySelector('body').getAttribute('c');
    if (country === 'COUNTRY') {
        return 'in';
    }
    return country;
}
/**
 * 设置页面标题
 */
export function setAppTitle() {
    switch (getCountry()) {
        case 'in':
            document.title = 'WINZONE789'
            break;
    }
}
/**
 * 设置皮肤模版
 */
 export function setSkinTemplate() {
    let country = document.querySelector('body').getAttribute('c');
    if (country === 'COUNTRY') country = 'in'
    let template = document.querySelector('body').getAttribute('s');
    if (template === 'TEMPLATE') template = 's1'
}
/**
 * 获取默认语言
 *
 * 印度   in_EN 
 * @returns {string}
 */
export function getDefaultLanguage() {
    if(localStorage.getItem("SET_LANG")){
        return localStorage.getItem("SET_LANG")
    }else{
        const country = getCountry();
        //const jsSrc =(navigator.language || navigator.browserLanguage).toLowerCase();
        if (country === 'in') {
            localStorage.setItem("SET_LANG",'in_EN');
            localStorage.setItem("ACCEPT_LANGUAGE",'en');
            return 'in_EN'
        }
    }
}
/**
 * 获取语言选项
 *
 * 印度   English , हिन्दी
 * @returns {string}
 */
 export function getLanguage() {
    const country = getCountry();
    if (country === 'in') {
        return [{name: 'English'},{name: 'हिन्दी'}]
    }else{
        return []
    }
}
/**
 * 获取当前语言文本 
 * @returns {string}
 */
 export function getLanguageText(language) { //TODO
    switch(language){
        case 'in_EN':
            return 'English'
    }
}
/**
 * 设置语言
 * 英语    'English'
 * 印地语   'हिन्दी'
 * @returns {string}
 */
 export function setLanguage(country, language) {
    switch(language){
        case 'English':
            localStorage.setItem("SET_LANG",country + '_EN');
            localStorage.setItem("ACCEPT_LANGUAGE",'en');
            break;
        case 'हिन्दी':
            localStorage.setItem("SET_LANG",country + '_HI');
            localStorage.setItem("ACCEPT_LANGUAGE",'hi');
            break;
        default:
            break; 
    }
}
/**
 * 设置 ACCEPT_LANGUAGE
 * 印度    'en',
 * @returns {string}
 */
 export function setAcceptLanguage() {
    if(!localStorage.getItem('ACCEPT_LANGUAGE')){
        //const jsSrc =(navigator.language || navigator.browserLanguage).toLowerCase();
        const country = getCountry();
        if (country === 'in') {
            localStorage.setItem("SET_LANG",'in_EN');
            localStorage.setItem("ACCEPT_LANGUAGE",'en');
            localStorage.setItem("countryImgUrl",'in/EN/');
        }else{
            localStorage.setItem("ACCEPT_LANGUAGE",'en');
            return
        }
    }
}
/**
 * 设置图片路径
 * @returns {string}
 */
 export function setImgUrl() {
    const currentLanguage = getDefaultLanguage()
    //const template = document.querySelector('body').getAttribute('s');
    switch (currentLanguage) {
        case "in_EN":
            localStorage.setItem("countryImgUrl","in/EN/S1/");
            break;
        default:
            break;
    }
}

/**
 * 设置最后一次游戏页面名称
 */
 export function setLastTimeGameName(gameId, platform) {
    if(gameId === 'lobby' && isNotBlank(localStorage.getItem('myLastTimeGame'))){
        return
    }else{
        localStorage.setItem("myLastTimeGame", gameId);
        localStorage.setItem("myLastTimeGamePlatform", platform);
        if(localStorage.getItem('myLastTimeGameList')){
            let list = JSON.parse(localStorage.getItem('myLastTimeGameList'))
            for(let i in list){
                if(list[i] === gameId){list.splice(i,1)}
            }
            if(list.length >= 4){list.pop()}
            list.unshift(gameId)
            localStorage.setItem("myLastTimeGameList", JSON.stringify(list))
        }else{
            let list = [gameId]
            localStorage.setItem("myLastTimeGameList", JSON.stringify(list))
        } 
    }
    if(isBlank(localStorage.getItem('firstVisit'))) {
        localStorage.setItem("firstVisit", gameId)
    }  
}

/**
 * 获取最后一次游戏页面名称
 * 默认返回 'lobby' 
 */
export function getLastTimeGameName() {
    if(localStorage.getItem('myLastTimeGame')){
        return localStorage.getItem('myLastTimeGame')
    }else{
        return 'lobby'
    } 
}
/**
 * 获取最后一次游戏页面平台名称
 * 默认返回 'lobby' 
 */
export function getLastTimeGamePlatform() {
    if(localStorage.getItem('myLastTimeGamePlatform')){
        return localStorage.getItem('myLastTimeGamePlatform')
    }else{
        return 'lobby'
    } 
}

/**
 * 根据游戏ID 查询所属平台
 */
export function searchPlatform(id) {
    if(id === 'lobby' || id === 'crash2' || id === 'parity'|| id === 'mine'|| id === 'wheel'|| id === 'andar'|| id === 'dice'){
        return "lobby"
    }else if(id.indexOf('cq9') > 0){
        return "cq9"
    }else{
        return "jili"
    }
}

/**
 * 限制试玩次数
 */
export function demoRestrictions(gameName) {
    let name = gameName + 'DemoNumber'
    let times = parseInt(localStorage.getItem(name)) + 1
    if(times > 10){
        return times
    }else{
        localStorage.setItem(name, times);
        return times
    }
}

/**
 * googlePlay top下载
 */
// export function showGooglePlayDownload(dom) {
//     let id = '#'+ dom
//     let element = ''
//     if(document.querySelector(id) !== null){
//         element = document.querySelector(id).style.display= 'block'; 
//         element.style.display= 'block'; 
//     }else{
//         return
//     }
//     return element
// }

// export function toGooglePlay(dom, url){
//     if(url !== null && url !== undefined){
//       document.querySelector('#googlePlayBtn').setAttribute("href", url)
//     }
//     if(dom !== null && dom !== undefined){
//       let id = '#'+ dom
//       let element = ''
//       if(document.querySelector(id) !== null){
//         element = document.querySelector(id);
//         element.style.display= 'block'; 
//         document.getElementById('downloadDialogPopup').style.display= 'block';
//       }
//     }
//   }

/**
 * 初始化设置
 */
export function init() {
    //设置页面标题
    setAppTitle()
    //设置图片路径
    setImgUrl()
    //设置 ACCEPT_LANGUAGE
    setAcceptLanguage()
    //设置皮肤模版
    setSkinTemplate()
}