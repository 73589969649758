<template>
  <div>
    <div class="AB-hd">
      <div class="c">
        <span class="title">{{ $t('andarBahar.H1') }}</span>
        <span class="l" @click="rechargeClick">
          {{ $filters.currencySymbol(me.availableAmount) }}
          <i class="l-c" v-if="me.vipLevel === 0">{{ me.chipAmount }}</i>
        </span>
        <span class="r" @click="toMyPlayList()"><i> </i></span>
        <span class="r2" @click="toGameLobby()"><i> </i></span>
      </div>
    </div>

    <div :class="gameInit.gamePaused ? 'AB-main AB-paused' : 'AB-main'">

      <div class="AB-ui" :class="dealBetClass ? 'deal-bet' : ''">
        <div class="AB-ui-text1"><span>{{ $t('andarBahar.Period') }}</span><strong>{{ currentGame.serialNum }}</strong></div>

        <div class="AB-ui-text2" v-if="currentGame.stage.indexOf('joker') >= 0" >
          <span>{{ $t('andarBahar.Count_Down') }}</span>
          <div v-if="currentGame.jokerBetRemainMillis > 0">
            <nut-countdown :endTime="currentGame.jokerBetRemainMillis" millisecond format="ss:SS" style="font-size:30px;"/>
          </div>
          <div v-else>
            {{ $t('andarBahar.Bet_end') }}
          </div>
        </div>
        <div class="AB-ui-text2" v-else-if="currentGame.stage === 'deal_bet' || currentGame.stage === 'deal_wait'" >
          <span>{{ $t('andarBahar.Count_Down') }}</span>
          <div v-if="currentGame.dealBetRemainMillis > 0">
            <nut-countdown :endTime="currentGame.dealBetRemainMillis" millisecond format="ss:SS" style="font-size:30px;"/>
          </div>
          <div v-else>
            {{ $t('andarBahar.Bet_end') }}
          </div>
        </div>

        <div class="AB-ui-text2" v-else-if="currentGame.stage === 'deal_animate' || currentGame.stage === 'deal_end'" >
          <span>{{ $t('andarBahar.Cards') }}</span>
          <div class="inline-block">
            <strong>{{ currentGame.cards }}</strong><span> / 51</span>
          </div>

        </div>


        <div class="AB-ui-text3">
          <span>{{ $t('andarBahar.My_Bet') }}</span>
          <strong v-if="myBet.suitAmount === 0 && myBet.num_invlAmount === 0 && myBet.sideAmount === 0 && myBet.seq_invlAmount === 0" style="text-align: center;">0</strong>
          <strong v-else>{{ currentGame.stage.indexOf('joker') >= 0 ? 'Joker' : 'Deal' }}</strong>
          <p v-if="myBet.suitAmount > 0">
            <strong><i :class="'i-'+ myBet.suit"></i> {{ $filters.currencySymbol(myBet.suitAmount) }}</strong>
          </p>
          <p v-if="myBet.num_invlAmount > 0">
            <strong>
              <i v-if="myBet.num_invl === 'below_7'" class="i-below_7">&lt;7</i>
              <i v-else-if="myBet.num_invl === 'exactly_7'" class="i-exactly_7">=7</i>
              <i v-else-if="myBet.num_invl === 'above_7'" class="i-above_7">&gt;7</i>
               {{ $filters.currencySymbol(myBet.num_invlAmount) }}
            </strong>
          </p>
          <p v-if="myBet.sideAmount > 0">
            <strong>
              <i v-if="myBet.side === 'andar'" class="i-andar">A<span>ndar</span></i>
              <i v-else-if="myBet.side === 'bahar'" class="i-bahar">B<span>ahar</span></i>
               {{ $filters.currencySymbol(myBet.sideAmount) }}
              </strong>
          </p>
          <p v-if="myBet.seq_invlAmount > 0">
            <strong>
              <i v-if="myBet.seq_invl === '1_5'" class="i-seq_invl">1-5</i> 
              <i v-else-if="myBet.seq_invl === '6_10'" class="i-seq_invl">6-10</i> 
              <i v-else-if="myBet.seq_invl === '11_15'" class="i-seq_invl">11-15</i> 
              <i v-else-if="myBet.seq_invl === '16_25'" class="i-seq_invl">16-25</i> 
              <i v-else-if="myBet.seq_invl === '26_30'" class="i-seq_invl">26-30</i> 
              <i v-else-if="myBet.seq_invl === '31_35'" class="i-seq_invl">31-35</i> 
              <i v-else-if="myBet.seq_invl === '36_40'" class="i-seq_invl">36-40</i> 
              <i v-else-if="myBet.seq_invl === '41_'" class="i-seq_invl">41+</i> 
              {{ $filters.currencySymbol(myBet.seq_invlAmount) }}
            </strong>
          </p>
        </div>

        <div class="AB-ui-a-container"></div>
        <div class="AB-ui-b-container"></div>
        <div class="AB-ui-J-container"></div>
        
        <div class="AB-ui-c-container"></div>
        
        <div class="AB-ui-a-name"><strong>A</strong>ndar</div>
        <div class="AB-ui-b-name"><strong>B</strong>ahar</div>
        <div class="AB-ui-J-name"><strong>J</strong>oker</div>
        <div class="AB-ui-cards" @click="rulePopClick"><span>{{ $t('andarBahar.Rules') }}</span><i class="nutui-iconfont nut-icon nut-icon-ask"></i></div>
        <!-- <div class="AB-ui-cards">Cards:<strong>0</strong><span> / 52</span></div> -->

        <div class="AB-ui-paibox" :class="'pai-' + currentGame.paiAnimate">

          <div class="pai-L p50" :class="dealCards[50] ? 'pai-L-50' : ''"><strong v-if="dealCards[50]" :class="'i-' + dealCards[50].suit">{{ dealCards[50].num }}</strong></div>
          <div class="pai-L p49" :class="dealCards[49] ? 'pai-L-49' : ''"><strong v-if="dealCards[49]" :class="'i-' + dealCards[49].suit">{{ dealCards[49].num }}</strong></div>
          <div class="pai-L p48" :class="dealCards[48] ? 'pai-L-48' : ''"><strong v-if="dealCards[48]" :class="'i-' + dealCards[48].suit">{{ dealCards[48].num }}</strong></div>
          <div class="pai-L p47" :class="dealCards[47] ? 'pai-L-47' : ''"><strong v-if="dealCards[47]" :class="'i-' + dealCards[47].suit">{{ dealCards[47].num }}</strong></div>
          <div class="pai-L p46" :class="dealCards[46] ? 'pai-L-46' : ''"><strong v-if="dealCards[46]" :class="'i-' + dealCards[46].suit">{{ dealCards[46].num }}</strong></div>
          <div class="pai-L p45" :class="dealCards[45] ? 'pai-L-45' : ''"><strong v-if="dealCards[45]" :class="'i-' + dealCards[45].suit">{{ dealCards[45].num }}</strong></div>
          <div class="pai-L p44" :class="dealCards[44] ? 'pai-L-44' : ''"><strong v-if="dealCards[44]" :class="'i-' + dealCards[44].suit">{{ dealCards[44].num }}</strong></div>
          <div class="pai-L p43" :class="dealCards[43] ? 'pai-L-43' : ''"><strong v-if="dealCards[43]" :class="'i-' + dealCards[43].suit">{{ dealCards[43].num }}</strong></div>
          <div class="pai-L p42" :class="dealCards[42] ? 'pai-L-42' : ''"><strong v-if="dealCards[42]" :class="'i-' + dealCards[42].suit">{{ dealCards[42].num }}</strong></div>
          <div class="pai-L p41" :class="dealCards[41] ? 'pai-L-41' : ''"><strong v-if="dealCards[41]" :class="'i-' + dealCards[41].suit">{{ dealCards[41].num }}</strong></div>

          <div class="pai-L p40" :class="dealCards[40] ? 'pai-L-40' : ''"><strong v-if="dealCards[40]" :class="'i-' + dealCards[40].suit">{{ dealCards[40].num }}</strong></div>
          <div class="pai-L p39" :class="dealCards[39] ? 'pai-L-39' : ''"><strong v-if="dealCards[39]" :class="'i-' + dealCards[39].suit">{{ dealCards[39].num }}</strong></div>
          <div class="pai-L p38" :class="dealCards[38] ? 'pai-L-38' : ''"><strong v-if="dealCards[38]" :class="'i-' + dealCards[38].suit">{{ dealCards[38].num }}</strong></div>
          <div class="pai-L p37" :class="dealCards[37] ? 'pai-L-37' : ''"><strong v-if="dealCards[37]" :class="'i-' + dealCards[37].suit">{{ dealCards[37].num }}</strong></div>
          <div class="pai-L p36" :class="dealCards[36] ? 'pai-L-36' : ''"><strong v-if="dealCards[36]" :class="'i-' + dealCards[36].suit">{{ dealCards[36].num }}</strong></div>
          <div class="pai-L p35" :class="dealCards[35] ? 'pai-L-35' : ''"><strong v-if="dealCards[35]" :class="'i-' + dealCards[35].suit">{{ dealCards[35].num }}</strong></div>
          <div class="pai-L p34" :class="dealCards[34] ? 'pai-L-34' : ''"><strong v-if="dealCards[34]" :class="'i-' + dealCards[34].suit">{{ dealCards[34].num }}</strong></div>
          <div class="pai-L p33" :class="dealCards[33] ? 'pai-L-33' : ''"><strong v-if="dealCards[33]" :class="'i-' + dealCards[33].suit">{{ dealCards[33].num }}</strong></div>
          <div class="pai-L p32" :class="dealCards[32] ? 'pai-L-32' : ''"><strong v-if="dealCards[32]" :class="'i-' + dealCards[32].suit">{{ dealCards[32].num }}</strong></div>
          <div class="pai-L p31" :class="dealCards[31] ? 'pai-L-31' : ''"><strong v-if="dealCards[31]" :class="'i-' + dealCards[31].suit">{{ dealCards[31].num }}</strong></div>

          <div class="pai-L p30" :class="dealCards[30] ? 'pai-L-30' : ''"><strong v-if="dealCards[30]" :class="'i-' + dealCards[30].suit">{{ dealCards[30].num }}</strong></div>
          <div class="pai-L p29" :class="dealCards[29] ? 'pai-L-29' : ''"><strong v-if="dealCards[29]" :class="'i-' + dealCards[29].suit">{{ dealCards[29].num }}</strong></div>
          <div class="pai-L p28" :class="dealCards[28] ? 'pai-L-28' : ''"><strong v-if="dealCards[28]" :class="'i-' + dealCards[28].suit">{{ dealCards[28].num }}</strong></div>
          <div class="pai-L p27" :class="dealCards[27] ? 'pai-L-27' : ''"><strong v-if="dealCards[27]" :class="'i-' + dealCards[27].suit">{{ dealCards[27].num }}</strong></div>
          <div class="pai-L p26" :class="dealCards[26] ? 'pai-L-26' : ''"><strong v-if="dealCards[26]" :class="'i-' + dealCards[26].suit">{{ dealCards[26].num }}</strong></div>
          <div class="pai-L p25" :class="dealCards[25] ? 'pai-L-25' : ''"><strong v-if="dealCards[25]" :class="'i-' + dealCards[25].suit">{{ dealCards[25].num }}</strong></div>
          <div class="pai-L p24" :class="dealCards[24] ? 'pai-L-24' : ''"><strong v-if="dealCards[24]" :class="'i-' + dealCards[24].suit">{{ dealCards[24].num }}</strong></div>
          <div class="pai-L p23" :class="dealCards[23] ? 'pai-L-23' : ''"><strong v-if="dealCards[23]" :class="'i-' + dealCards[23].suit">{{ dealCards[23].num }}</strong></div>
          <div class="pai-L p22" :class="dealCards[22] ? 'pai-L-22' : ''"><strong v-if="dealCards[22]" :class="'i-' + dealCards[22].suit">{{ dealCards[22].num }}</strong></div>
          <div class="pai-L p21" :class="dealCards[21] ? 'pai-L-21' : ''"><strong v-if="dealCards[21]" :class="'i-' + dealCards[21].suit">{{ dealCards[21].num }}</strong></div>

          <div class="pai-L p20" :class="dealCards[20] ? 'pai-L-20' : ''"><strong v-if="dealCards[20]" :class="'i-' + dealCards[20].suit">{{ dealCards[20].num }}</strong></div>
          <div class="pai-L p19" :class="dealCards[19] ? 'pai-L-19' : ''"><strong v-if="dealCards[19]" :class="'i-' + dealCards[19].suit">{{ dealCards[19].num }}</strong></div>
          <div class="pai-L p18" :class="dealCards[18] ? 'pai-L-18' : ''"><strong v-if="dealCards[18]" :class="'i-' + dealCards[18].suit">{{ dealCards[18].num }}</strong></div>
          <div class="pai-L p17" :class="dealCards[17] ? 'pai-L-17' : ''"><strong v-if="dealCards[17]" :class="'i-' + dealCards[17].suit">{{ dealCards[17].num }}</strong></div>
          <div class="pai-L p16" :class="dealCards[16] ? 'pai-L-16' : ''"><strong v-if="dealCards[16]" :class="'i-' + dealCards[16].suit">{{ dealCards[16].num }}</strong></div>
          <div class="pai-L p15" :class="dealCards[15] ? 'pai-L-15' : ''"><strong v-if="dealCards[15]" :class="'i-' + dealCards[15].suit">{{ dealCards[15].num }}</strong></div>
          <div class="pai-L p14" :class="dealCards[14] ? 'pai-L-14' : ''"><strong v-if="dealCards[14]" :class="'i-' + dealCards[14].suit">{{ dealCards[14].num }}</strong></div>
          <div class="pai-L p13" :class="dealCards[13] ? 'pai-L-13' : ''"><strong v-if="dealCards[13]" :class="'i-' + dealCards[13].suit">{{ dealCards[13].num }}</strong></div>
          <div class="pai-L p12" :class="dealCards[12] ? 'pai-L-12' : ''"><strong v-if="dealCards[12]" :class="'i-' + dealCards[12].suit">{{ dealCards[12].num }}</strong></div>
          <div class="pai-L p11" :class="dealCards[11] ? 'pai-L-11' : ''"><strong v-if="dealCards[11]" :class="'i-' + dealCards[11].suit">{{ dealCards[11].num }}</strong></div>

          <div class="pai-L p10" :class="dealCards[10] ? 'pai-L-10' : ''"><strong v-if="dealCards[10]" :class="'i-' + dealCards[10].suit">{{ dealCards[10].num }}</strong></div>
          <div class="pai-L p9" :class="dealCards[9] ? 'pai-L-9' : ''"><strong v-if="dealCards[9]" :class="'i-' + dealCards[9].suit">{{ dealCards[9].num }}</strong></div>
          <div class="pai-L p8" :class="dealCards[8] ? 'pai-L-8' : ''"><strong v-if="dealCards[8]" :class="'i-' + dealCards[8].suit">{{ dealCards[8].num }}</strong></div>
          <div class="pai-L p7" :class="dealCards[7] ? 'pai-L-7' : ''"><strong v-if="dealCards[7]" :class="'i-' + dealCards[7].suit">{{ dealCards[7].num }}</strong></div>
          <div class="pai-L p6" :class="dealCards[6] ? 'pai-L-6' : ''"><strong v-if="dealCards[6]" :class="'i-' + dealCards[6].suit">{{ dealCards[6].num }}</strong></div>
          <div class="pai-L p5" :class="dealCards[5] ? 'pai-L-5' : ''"><strong v-if="dealCards[5]" :class="'i-' + dealCards[5].suit">{{ dealCards[5].num }}</strong></div>
          <div class="pai-L p4" :class="dealCards[4] ? 'pai-L-4' : ''"><strong v-if="dealCards[4]" :class="'i-' + dealCards[4].suit">{{ dealCards[4].num }}</strong></div>
          <div class="pai-L p3" :class="dealCards[3] ? 'pai-L-3' : ''"><strong v-if="dealCards[3]" :class="'i-' + dealCards[3].suit">{{ dealCards[3].num }}</strong></div>
          <div class="pai-L p2" :class="dealCards[2] ? 'pai-L-2' : ''"><strong v-if="dealCards[2]" :class="'i-' + dealCards[2].suit">{{ dealCards[2].num }}</strong></div>
          <div class="pai-L p1" :class="dealCards[1] ? 'pai-L-1' : ''"><strong v-if="dealCards[1]" :class="'i-' + dealCards[1].suit">{{ dealCards[1].num }}</strong></div>

          <div class="pai-L p0" :class="dealCards[0] ? 'pai-L-0' : ''"><strong v-if="dealCards[0]" :class="'i-' + dealCards[0].suit">{{ dealCards[0].num }}</strong></div>

          <div class="pai-L pj" :class="currentGame.jokerAnimation ? 'pai-J' : ''">
            <strong v-show="joker.suit" :class="'front i-'+ joker.suit">{{ joker.num }}</strong>
          </div>

          <!-- <div class="pai-L pai-J front"><strong :class="'i-' + joker.suit">{{ joker.num }}</strong></div> -->
        </div>

        <div class="pk" v-if="currentGame.stage === 'deal_wait'">
          <div class="c" :class="currentGame.stage === 'deal_wait' ? 'pk-c' : ''"></div>
          <div class="l" :class="currentGame.stage === 'deal_wait' ? 'pk-left' : ''"></div>
          <div class="r" :class="currentGame.stage === 'deal_wait' ? 'pk-right' : ''"></div>
          <div class="t" :class="currentGame.stage === 'deal_wait' ? 'pk-t' : ''"><span>{{ $t('andarBahar.START') }}</span></div>
        </div>

        <div class="pk-win l"  :class="pkWinShow && pkWinSide === 'andar' ? 'pk-win-show' : ''"></div>
        <div class="pk-win r"  :class="pkWinShow && pkWinSide === 'bahar' ? 'pk-win-show' : ''"></div>

        <div class="g-paused"><strong>{{ $t('game.gamePaused') }}</strong></div>

        <div class="g-next" v-if="waitNext"><strong>{{ $t('andarBahar.Wait_for_the_next_round') }}</strong></div>
        
        <div class="win-amount-icon a1" :class="myBet.totalWinAmount > 0 ? 'win-icon-animate' : ''"></div>
        <div class="win-amount-icon a2" :class="myBet.totalWinAmount > 0 ? 'win-icon-animate' : ''"></div>
        <div class="win-amount-icon a3" :class="myBet.totalWinAmount > 0 ? 'win-icon-animate' : ''"></div>
        <div class="win-amount-icon a4" :class="myBet.totalWinAmount > 0 ? 'win-icon-animate' : ''"></div>
        <div class="win-amount-icon a5" :class="myBet.totalWinAmount > 0 ? 'win-icon-animate' : ''"></div>
        <div class="win-amount" :class="myBet.totalWinAmount > 0 ? 'win-text-animate' : ''" v-if="myBet.totalWinAmount > 0"><span>+{{ $filters.currencySymbol(myBet.totalWinAmount) }}</span></div>

      </div>


      <div class="AB-notice" :class="noticeAnimate ? 'notice-animate':' '"><strong>{{ noticeText }}</strong></div>

      <div class="AB-bet">

        <div :class="currentGame.stage === 'joker_bet' ? 'AB-bet-bd' : 'AB-bet-bd disabled'" 
          v-if="currentGame.stage === 'joker_bet' || 
            currentGame.stage === 'joker_wait' || 
            currentGame.stage === 'joker_end'">
          <h4>{{ $t('andarBahar.Bet_Joker') }}</h4>

          <p>{{ $t('andarBahar.Suit_Of_Joker_Card') }}</p>
          <ul>
            <li @click="betClick('suit','spade', (myBet.suit !== 'spade' && myBet.suitAmount > 0))"
               :class="myBet.suit !== 'spade' && myBet.suitAmount > 0 ? 'disabled': ''">
              <i class="i-spade"></i><span>4x</span></li>

            <li @click="betClick('suit','heart', (myBet.suit !== 'heart' && myBet.suitAmount > 0))"
               :class="myBet.suit !== 'heart' && myBet.suitAmount > 0 ? 'disabled': ''">
              <i class="i-heart"></i><span>4x</span></li>

            <li @click="betClick('suit','club', (myBet.suit !== 'club' && myBet.suitAmount > 0))" 
              :class="myBet.suit !== 'club' && myBet.suitAmount > 0 ? 'disabled': ''">
              <i class="i-club"></i><span>4x</span></li>

            <li @click="betClick('suit','diamond', (myBet.suit !== 'diamond' && myBet.suitAmount > 0))"
               :class="myBet.suit !== 'diamond' && myBet.suitAmount > 0 ? 'disabled': ''">
              <i class="i-diamond"></i><span>4x</span></li>

            <!-- <div class="vip-disabled" v-if="me.vipLevel === 0" @click="vipPopupClick"><span>{{ $t('andarBahar.VIP_Only') }}</span></div> -->
          </ul>

          <p>{{ $t('andarBahar.Value_Of_Joker_Card') }}</p>
          <ul class="ul-7">
            <li @click="betClick('num_invl','below_7', (myBet.num_invl !== 'below_7' && myBet.num_invlAmount > 0))"
              :class="myBet.num_invl !== 'below_7' && myBet.num_invlAmount > 0 ? 'disabled': ''">
              <i class="i-1_6">&lt;7</i><span>2x</span></li>

            <li @click="betClick('num_invl','exactly_7', (myBet.num_invl !== 'exactly_7' && myBet.num_invlAmount > 0))"
              :class="myBet.num_invl !== 'exactly_7' && myBet.num_invlAmount > 0 ? 'disabled': ''">
              <i class="i-7_7">=7</i><span>12x</span></li>

            <li @click="betClick('num_invl','above_7', (myBet.num_invl !== 'above_7' && myBet.num_invlAmount > 0))"
              :class="myBet.num_invl !== 'above_7' && myBet.num_invlAmount > 0 ? 'disabled': ''">
              <i class="i-8_13">&gt;7</i><span>2x</span></li>

              <!-- <div class="vip-disabled" v-if="me.vipLevel === 0" @click="vipPopupClick"><span>{{ $t('andarBahar.VIP_Only') }}</span></div> -->
          </ul>

          
        </div>


        <div :class="currentGame.stage === 'deal_bet' ? 'AB-bet-bd bg2' : 'AB-bet-bd bg2 disabled'"
          v-if="currentGame.stage === 'deal_bet' || 
          currentGame.stage === 'deal_wait' || 
          currentGame.stage === 'deal_animate' || 
          currentGame.stage === 'deal_end'" >
          <h4>{{ $t('andarBahar.Bet_Deal') }}</h4>
          <p>{{ $t('andarBahar.Winning_Side') }}</p>
          <ul class="ul-ab">
            <li @click="betClick('side','andar', (myBet.side !== 'andar' && myBet.sideAmount > 0))"
              :class="myBet.side !== 'andar' && myBet.sideAmount > 0 ? 'disabled': ''">
              <i class="i-a">Andar</i><span>1.9x</span></li>
            <li @click="betClick('side','bahar', (myBet.side !== 'bahar' && myBet.sideAmount > 0))"
              :class="myBet.side !== 'bahar' && myBet.sideAmount > 0 ? 'disabled': ''">
              <i class="i-b">Bahar</i><span>2x</span></li>
          </ul>
          <p>{{ $t('andarBahar.Cards_To_Be_Dealt') }}</p>
          <ul class="ul-n">
            <li @click="betClick('seq_invl','1_5', (myBet.seq_invl !== '1_5' && myBet.seq_invlAmount > 0))"
              :class="myBet.seq_invl !== '1_5' && myBet.seq_invlAmount > 0 ? 'disabled': ''">
              <i class="i-n">1-5</i><span>3x</span></li>

            <li @click="betClick('seq_invl','6_10', (myBet.seq_invl !== '6_10' && myBet.seq_invlAmount > 0))"
              :class="myBet.seq_invl !== '6_10' && myBet.seq_invlAmount > 0 ? 'disabled': ''">
              <i class="i-n">6-10</i><span>4x</span></li>

            <li @click="betClick('seq_invl','11_15', (myBet.seq_invl !== '11_15' && myBet.seq_invlAmount > 0))"
              :class="myBet.seq_invl !== '11_15' && myBet.seq_invlAmount > 0 ? 'disabled': ''">
              <i class="i-n">11-15</i><span>5x</span></li>

            <li @click="betClick('seq_invl','16_25', (myBet.seq_invl !== '16_25' && myBet.seq_invlAmount > 0))"
              :class="myBet.seq_invl !== '16_25' && myBet.seq_invlAmount > 0 ? 'disabled': ''">
              <i class="i-n">16-25</i><span>4x</span></li>

            <li @click="betClick('seq_invl','26_30', (myBet.seq_invl !== '26_30' && myBet.seq_invlAmount > 0))"
              :class="myBet.seq_invl !== '26_30' && myBet.seq_invlAmount > 0 ? 'disabled': ''">
              <i class="i-n">26-30</i><span>15x</span></li>

            <li @click="betClick('seq_invl','31_35', (myBet.seq_invl !== '31_35' && myBet.seq_invlAmount > 0))"
              :class="myBet.seq_invl !== '31_35' && myBet.seq_invlAmount > 0 ? 'disabled': ''">
              <i class="i-n">31-35</i><span>25x</span></li>

            <li @click="betClick('seq_invl','36_40', (myBet.seq_invl !== '36_40' && myBet.seq_invlAmount > 0))"
             :class="myBet.seq_invl !== '36_40' && myBet.seq_invlAmount > 0 ? 'disabled': ''">
              <i class="i-n">36-40</i><span>50x</span></li>

            <li @click="betClick('seq_invl','41_', (myBet.seq_invl !== '41_' && myBet.seq_invlAmount > 0))"
              :class="myBet.seq_invl !== '41_' && myBet.seq_invlAmount > 0 ? 'disabled': ''">
              <i class="i-n">41+</i><span>120x</span></li>

            <!-- <div class="vip-disabled" v-if="me.vipLevel === 0" @click="vipPopupClick"><span>{{ $t('andarBahar.VIP_Only') }}</span></div> -->
          </ul>
        </div>
      </div>


      <div class="AB-record" >
        <h4>{{ $t('andarBahar.Record') }}</h4>
        <ul>
          <li v-for="(item, index) in histories" :key="index">
            <span :class="item.type === 'joker' ? 'seria-num' : ' '">
              {{ item.serialNum.substr(-4) }}
              <br/>
              {{ item.type === 'joker' ? 'Joker' : 'Deal' }}
            </span>
            <strong v-if="item.suit" :class="'i-'+ item.suit">{{ item.num }}</strong>
            <strong v-if="item.side" :class="'c-' + item.side">{{item.side.substr(0,1).toUpperCase()}}</strong>
            <strong v-if="item.seq" class="c-n">{{ item.seq }}</strong>
          </li>
        </ul>
      </div>


 
      <div class="tabs tabs-2">
          <strong :class="orderTabActive ? 'a' : ''" @click="playersList">{{ $t('PARITY.EVERYONE_ORDER') }}</strong>
          <strong :class="orderTabActive ? '' : 'a'" @click="myHistoryList">{{ $t('PARITY.MY_ORDER') }}</strong>
      </div>
      <div class="result-announcement">
          <div class="g-players">

            <!--**************************************** 所有玩家的订单 ****************************************-->
            <ul v-show="orderTabActive">
              <li>
                <span class="c1">{{ $t('PARITY.USER') }}</span>
                <span class="c3">{{ $t('andarBahar.Choose') }}</span>
                <span class="c4">{{ $t('andarBahar.Bet') }}</span>
              </li>
              <li v-for="(item, index) in usersBetList" :key="index">
                <span  class="c1">
                  <img :src="require('../assets/images/avatar/' + item.avatar)" />
                  {{ item.mobile }}
                </span>
                <span class="c3" style="width:70px;">
                  <strong class="s1" :class="'suit-' + item.betTarget" v-if="item.betType === 'suit'"></strong>

                  <strong class="s1" v-else-if="item.betType === 'num_invl'">
                    {{ item.betTarget === 'below_7' ? '>7' : ''}}
                    {{ item.betTarget === 'exactly_7' ? '=7' : ''}}
                    {{ item.betTarget === 'above_7' ? '<7' : ''}}
                  </strong>

                  <strong v-else-if="item.betType === 'side'" style="font-size: 18px;line-height: 20px;" :class="item.betTarget === 'andar' ? 's1 c-r':'s1'">
                    {{ item.betTarget === 'andar' ? 'A' : 'B' }}
                  </strong>
                  <strong class="s1" v-else-if="item.betType === 'seq_invl'" style="width: 30px; font-size: 10px; font-weight: 300;">
                    {{ item.betTarget === '1_5' ? '1-5' : '' }}
                    {{ item.betTarget === '6_10' ? '6-10' : '' }}
                    {{ item.betTarget === '11_15' ? '11-15' : '' }}
                    {{ item.betTarget === '16_25' ? '16-25' : '' }}
                    {{ item.betTarget === '26_30' ? '26-30' : '' }}
                    {{ item.betTarget === '31_35' ? '31-35' : '' }}
                    {{ item.betTarget === '36_40' ? '36-40' : '' }}
                    {{ item.betTarget === '41_' ? '41+' : '' }}
                  </strong>
                </span>
                <span class="c4" style="color: #bbaf78;">{{ $filters.currencySymbol(item.originBetAmount) }}</span>
              </li>
            </ul>

            <!--**************************************** 我的订单 ****************************************-->
            <ul v-show="!orderTabActive">
              <li>
                <span class="c1">{{ $t('andarBahar.Num') }}</span>
                <span class="c2">{{ $t('andarBahar.Bet') }}</span>
                <span class="c3">{{ $t('andarBahar.Choose') }}</span>
                <span class="c4">{{ $t('andarBahar.Win') }}</span>
              </li>

              <li v-for="myPlay in myPlays" :key="myPlay.id">
                <span class="c1">{{ myPlay.serialNum }}</span>
                <span class="c2">{{ $filters.currencySymbol(myPlay.originBetAmount) }}</span>
                <span class="c3">
                  <strong class="s1" :class="'suit-' + myPlay.betTarget" v-if="myPlay.betType === 'suit'"></strong>

                  <strong class="s1" v-else-if="myPlay.betType === 'num_invl'">
                    {{ myPlay.betTarget === 'below_7' ? '>7' : ''  }}
                    {{ myPlay.betTarget === 'exactly_7' ? '=7' : ''}}
                    {{ myPlay.betTarget === 'above_7' ? '<7' : ''  }}
                  </strong>
                  <strong class="s1" v-else-if="myPlay.betType === 'side'" style="font-size: 18px;line-height: 20px;">
                    {{ myPlay.betTarget === 'bahar' ? 'A' : 'B' }}
                  </strong>
                  <strong class="s1" v-else-if="myPlay.betType === 'seq_invl'" style="width: 30px; font-size: 10px; font-weight: 300;">
                    {{ myPlay.betTarget === '1_5' ? '1-5' : '' }}
                    {{ myPlay.betTarget === '6_10' ? '6-10' : '' }}
                    {{ myPlay.betTarget === '11_15' ? '11-15' : '' }}
                    {{ myPlay.betTarget === '16_25' ? '16-25' : '' }}
                    {{ myPlay.betTarget === '26_30' ? '26-30' : '' }}
                    {{ myPlay.betTarget === '31_35' ? '31-35' : '' }}
                    {{ myPlay.betTarget === '36_40' ? '36-40' : '' }}
                    {{ myPlay.betTarget === '41_' ? '41+' : '' }}
                  </strong>
                </span>
                <span class="c4" v-if="myPlay.win" style="color: #015c00;">+ {{ $filters.currencySymbol(myPlay.winAmount) }}</span>
                <span class="c4" v-else style="color: #015c00;">&nbsp;&nbsp;</span>
              </li>

              <li class="more" @click="toMyPlayList">{{ $t('game.more') }} >></li>
              
            </ul>
            
          </div>
      </div>   

    </div>

    <div class="loadingbox" v-if="loadingbox || gameInit.gamePaused">
      <div class="g-paused" v-if="gameInit.gamePaused"><strong>{{ $t('game.gamePaused') }}</strong></div>
    </div>

    <!--**************************************** 下注面板 ****************************************-->
    <nut-popup position="bottom" closeable v-model:visible="showBetPanel" pop-class="betPanelAB">
      <div class="betting-panel">

        <h2><strong>{{ currentGame.stage.indexOf('joker') >= 0 ? 'Joker' : 'Deal' }}</strong> {{ $t('andarBahar.Bet') }}</h2>

        <div class="panel-hd">
          <div class="l">
            <span>{{ $t('PARITY.BALANCE') }}:</span>
            <strong class="i-1">{{ $filters.currencySymbol(parseAmount(me.availableAmount)) }}</strong>
            <strong class="i-2"  v-if="me.vipLevel === 0">{{ me.chipAmount }}</strong>
            <nut-button size="small" type="primary" @click="rechargeClick" >
              {{ $t('home.recharge') }}
            </nut-button>
          </div>
          <div class="r">
            <li>
              <span>{{ $t('PARITY.FEE') }}:</span>
              <strong>{{ gameInit.feeRatio }}%</strong>
            </li>
          </div>
        </div>

        <div class="tg" v-if="myBet.betType === 'suit'">
          <strong>{{ $t('andarBahar.Suit_Of_Joker_Card') }}:</strong>
          <span :class="'suit-' + myBet.betTarget"></span>
        </div>
        <div class="tg" v-else-if="myBet.betType === 'num_invl'">
          <strong>{{ $t('andarBahar.Value_Of_Joker_Card') }}:</strong>
          <span v-if="myBet.betTarget === 'below_7'">A / 2 / 3 / 4 / 5 / 6</span>
          <span v-else-if="myBet.betTarget === 'exactly_7'">7</span>
          <span v-else-if="myBet.betTarget === 'above_7'">8 / 9 / 10 / J / Q / K</span>
        </div>
        <div class="tg" v-else-if="myBet.betType === 'side'">
          <strong>{{ $t('andarBahar.Winning_Side') }}:</strong>
          <span v-if="myBet.betTarget === 'andar'">Andar</span>
          <span v-else-if="myBet.betTarget === 'bahar'">Bahar</span>
        </div>
        <div class="tg" v-else-if="myBet.betType === 'seq_invl'">
          <strong>{{ $t('andarBahar.Cards_To_Be_Dealt') }}:</strong>
          <span v-if="myBet.betTarget === '1_5'">1 / 2 / 3 / 4 / 5</span>
          <span v-else-if="myBet.betTarget === '6_10'">6 / 7 / 8 / 9 / 10</span>
          <span v-else-if="myBet.betTarget === '11_15'">11 / 12 / 13 / 14 / 15</span>
          <span v-else-if="myBet.betTarget === '16_25'">16 / 17 / 18 / 19 / 20 / 21 / 22 / 23 / 24 / 25</span>
          <span v-else-if="myBet.betTarget === '26_30'">26 / 27 / 28 / 29 / 30</span>
          <span v-else-if="myBet.betTarget === '31_35'">31 / 32 / 33 / 34 / 35</span>
          <span v-else-if="myBet.betTarget === '36_40'">36 / 37 / 38 / 39 / 40</span>
          <span v-else-if="myBet.betTarget === '41_'">41 / 42 / 43 / 44 / 45 / 46 / 47 / 48 / 49 / 50 / 51</span>
        </div>

        <div class="panel-bd">

          <div class="btnbox" >
            <button v-for="(item, index) in gameInit.recommendBetAmountList" :key="index" 
              :class="currentGame.btnActiveInx === index ? 'btn a' : 'btn'"
              :disabled="item >= currentGame.minBetAmount && item <= currentGame.maxBetAmount? false : true"
              @click="btnBetClick(item, index)">{{ item }}
            </button>
            <button :class="currentGame.btnActiveInx === gameInit.recommendBetAmountList.length ? 'btn a' : 'btn'" 
              @click="btnBetClick(me.availableAmount, gameInit.recommendBetAmountList.length)">ALL</button>
          </div>



          <div class="inpbox">
            <button class="btn-bet btn-sub" @click="btnSubtract">-</button>
            <input v-model="myBet.betAmount" class="inp-bet" type="text" readonly/>
            <button class="btn-bet btn-plus" @click="btnAddition">+</button>
          </div>
        </div>

        <div class="btn-confirm">
          <nut-button @click="bet" block type="info" class="btn-bg-g" :disabled="myBet.betting">
            <nut-icon v-if="myBet.betting" name="loading" size="25" style="margin-top: 15px;"></nut-icon>
            <span v-else>{{ $t('PARITY.CONFIRM') }} {{ $filters.currencySymbol(myBet.betAmount) }}</span>
          </nut-button>
        </div>

      </div>
    </nut-popup>

    <!--**************************************** 结算面板 ****************************************-->
    <nut-popup pop-class="pop-mine-inventory" v-model:visible="showSettlePanel" :close-on-click-overlay="false">
      <div class="main win">
        <div class="hd">
          
        </div>
        <div class="bd">
          
        </div>
        <div class="btn">
          <nut-button type="primary" @click="closeSettlePanel">{{ $t('global.okText') }}</nut-button>
        </div>
      </div>
    </nut-popup>

    <!--**************************************** 游戏规则 ****************************************-->
    <nut-popup position="top" closeable round :style="{ height: '70%' }" v-model:visible="rulePopup">
        <GameRulesMine></GameRulesMine>
    </nut-popup>


    <!--**************************************** Big win ****************************************-->
    <nut-popup pop-class="pop-top-bigwin" v-model:visible="bigwinData.show" position="left" :closeable="true" :overlay="false" style="top: 5px !important;">
      <div class="bd">
        <div class="t1"><img class="avatar" :src="require('../assets/images/global/bigwin.png')" >
            <span>{{ bigwinData.data.mobile }}</span>
          </div>
        <div v-if="bigwinData.data.appTypeCode === 'crash'" class="t2 crash"><span>{{ bigwinData.data.crashEscapeRatio }}x</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'parity'" class="t2 parity"><span>{{ bigwinData.data.parityRoomName }}</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'wheel'" class="t2 wheel"><span> </span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'mine'" class="t2 mine"><span>{{ bigwinData.data.mineRowCount }} X {{ bigwinData.data.mineRowCount }}</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'andar'" class="t2 andar"><span></span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'dice'" class="t2 dice"><span></span></div>
        <div class="t3"><span>WIN</span><strong>{{ bigwinData.data.winAmount }}</strong></div>
      </div>
    </nut-popup>

    <!--**************************************** Broadcast Msg ****************************************-->
    <nut-popup pop-class="pop-top-broadcast-msg" v-model:visible="broadcastMsgData.show" position="left" :closeable="true" :overlay="false" style="top: 5px !important;">
      <div class="bd">
        <div class="t4" v-if="broadcastMsgData.type === 'parity_prediction'">&nbsp;</div>
        <div class="t5"><span>{{ broadcastMsgData.message}}</span></div>
      </div>
    </nut-popup>

    <!--**************************************** 游戏规则 ****************************************-->
    <nut-popup position="top" closeable round :style="{ height: '70%', background:'#1a2235'  }" v-model:visible="rulePopup">
        <GameRulesAndar></GameRulesAndar>
    </nut-popup>

    <!--**************************************** vip权益 ****************************************-->
    <nut-popup pop-class="pop-vip-lobby" v-model:visible="vipPopup">
      <VipRules :myLevel="me.vipLevel" :popTitle="popTitle" :tabActive="tabActive"></VipRules>
      <div class="login-btn m10">
        <nut-button block type="info" @click="rechargeClick" >{{$t('recharge.levelUpVip')}}</nut-button>
      </div>
    </nut-popup>




      <!--**************************************** 满100弹框 ****************************************-->
      <nut-popup pop-class="pop-withdrawal" v-model:visible="rechargePopup">
        <div class="hd">You are awesome!</div>
        <div class="bd">
          You have won <strong style="color:#00ffb3">₹100</strong> chips, 
          reached the upper limit and need to complete any first deposit to continue the game and unlock chips. 
        </div>
        <div class="btn">
          <nut-button type="primary" @click="rechargePopup = false">Close</nut-button>
          <nut-button type="info" @click="rechargeClick">Deposit</nut-button>
        </div>
      </nut-popup>


    
  </div>
</template>

<script>
import { reactive, toRefs } from "vue"
import { useRouter } from "vue-router"
import { Toast } from "@nutui/nutui"
import { getCountry,setLastTimeGameName } from "@/assets/js/environment"
import {isBlank, isNotBlank, parseProbabilityTarget} from "@/assets/js/commonUtil"
import axios from "axios"
import router from "@/router"
import GameRulesAndar from "../components/GameRulesAndar.vue"
import {commonEvent, createSocket, disconnectSocket, initGame} from "@/assets/js/game";
import { getCommonInfoAttr } from "@/assets/js/commonInfo";
import VipRules from "../components/VipRules.vue";

export default {
  name: "andarBahar",
  components: {
    GameRulesAndar: GameRulesAndar,
    VipRules: VipRules
  },
  data() {
    return {
      country: null,  //当前国家
      commonInfo: null,  //公共信息
      showBetPanel: false, //下注面板显示开关
      showSettlePanel: false,
      rulePopup: false, //规则显示开关
      orderTabActive: true, //订单列表 tab 切换开关
      rechargePopup: false,
      loadingbox: true,
      dealBetClass: false,
      waitNext: false,
      vipPopup: false,
      popTitle: this.$t('recharge.popTitle_1'),
      imgUrl : localStorage.getItem('countryImgUrl'),
      timer: null,
      myPlays: [],
      everyoneOrders: [],
      histories: [],
      noticeAnimate: false,
      noticeText:'',
      pkWinShow: false,
      pkWinSide: '',
      me:{
        vipLevel: 0,	//vip等级
        availableAmount: 0,	//可用余额	
        chipAmount: 0,
        rechargeAmount: 0,	//充值金额	
        withdrawAmount: 0,	//提现金额	
      },
      myBet: {
        betAmount: 0,
        betType: '',
        betTarget: '',
        betting: false,
        totalWinAmount: 0,
        suit:'',
        suitAmount:0,
        num_invl:'',
        num_invlAmount:0,
        side:'',
        sideAmount:0,
        seq_invl:'',
        seq_invlAmount:0,
      },
      myBetList:[],
      dealCards:[],
      usersBetList:[],
      gameInit: {
        betDeadline: 15,
        gamePaused: false,
        vtoken: '',
        recommendBetAmountList: [],	//vip1+ 推荐下注金额	array
        feeRatio: '',	//手续费点位
        appTaskReward: '',	//下载App任务奖励金额，
        appTaskStatus: '',	//下载App任务状态
      },
      currentGame:{
        serialNum: '',
        stage: '',	//阶段: joker_bet =下注  joker_wait =等待  joker_end =结束 
                    // deal_bet =下注  deal_wait =等待 deal_animate =动画 deal_end =结束
        jokerBetRemainMillis: '',	//小丑轮下注剩余毫秒数
        dealAnimateStartMillis: '',	//发牌轮动画开始毫秒数
        dealBetRemainMillis: '',	//发牌轮下注剩余毫秒数
        defaultBetAmount: 10,
        minBetAmount: 10,
        maxBetAmount: 100000,
        playing: false,
        btnActiveInx: 0,
        paiAnimate: null,
        jokerAnimation: false,
        cards: 0,
      },
      joker: {
        suit: '',
        num: '',
      },
      //event
      eventEnum: {
        initRoom: "s_ab_init_room", //初始化房间

        jokerBet: "s_ab_joker_bet", //开始小丑牌下注阶段
        jokerWait: "s_ab_joker_wait", //开始小丑牌待阶段
        jokerEnd: "s_ab_joker_end", //小丑牌结束阶段

        dealBet: "s_ab_deal_bet", //开始发牌下注阶段
        dealWait: "s_ab_deal_wait", //开始发牌待阶段
        dealBetAnimate: "s_ab_deal_animate", //发牌动画阶段
        dealEnd: "s_ab_deal_end", //发牌结束阶段
         
        pause: "s_ab_pause", //游戏暂停
        betNotify: "s_ab_bet", //任意玩家的下注通知
        settle: "s_ab_settle", //结算信息
        playerInfo: "s_ab_player", //当前局玩家信息

      },
      bigwinData: {
        show: false,
        start: false,
        list: [],
        data: {}
      },
      broadcastMsgData: {
        show: false,
        type: '',
        message: '',
      },
    };
  },
  created() {
    this.country = getCountry() //当前国家
    this.commonInfo = JSON.parse(localStorage.getItem("commonInfo"))
    this.gameInit.recommendBetAmountList = this.commonInfo.recommendBetAmountList;
    this.currentGame.minBetAmount = Math.floor(this.commonInfo.betAmountRange.min)
    this.currentGame.maxBetAmount = Math.floor(this.commonInfo.betAmountRange.max)


    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "visible") {
        router.go(0)
      }
      if (document.visibilityState === "hidden") { 
        disconnectSocket('andar', this.socket);
      }
    })

  },
  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      }
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  
  // ======================================== mounted ========================================
  mounted() {

    Toast.loading('Loading...', {
      id: "init",
      duration: 0,
      bgColor: "rgba(0, 0, 0, 0.9)",
      cover: true,
    });
    /**
     * 初始化接口
     */
     initGame('andar').then((response) => {
      Toast.hide("init");
      const data = response.data.data;
      if(data.gamePaused){
        this.gameInit.gamePaused = data.gamePaused
        return
      }
      this.me.vipLevel = data.vipLevel
      this.me.availableAmount = data.availableAmount
      this.me.chipAmount = data.chipBalance
      if(this.me.vipLevel === 0 && this.me.chipAmount >= 100){
        this.rechargePopup = true
      }
      this.me.rechargeAmount = data.rechargeAmount
      this.me.withdrawAmount = data.withdrawAmount
      this.gameInit.gamePaused = data.gamePaused

      this.gameInit.feeRatio = data.feeRatio
      this.gameInit.appTaskReward = data.appTaskReward
      this.gameInit.appTaskStatus = data.appTaskStatus
      this.currentGame.serialNum = data.serialNum
      this.currentGame.stage = data.stage

      if (data.stage === this.eventEnum.pause) { //游戏暂停
        this.currentGame.pause = true;
      }else if(data.stage === 'joker_bet'){
        this.usersBet('joker',data.jokerBetRemainMillis)
      }else if(data.stage === 'joker_wait'){  //开始小丑牌等待阶段
        this.resetMyBet()
        this.currentGame.stage = 'joker_wait'
        this.currentGame.paiAnimate = 'joker-wait'
        setTimeout(() => {
          this.currentGame.jokerAnimation = true
        }, 2000);

      }else if(data.stage === 'joker_end'){  //小丑牌结束阶段
        this.currentGame.stage = 'joker_end'
        this.joker.suit = data.joker.suit
        this.joker.num = data.joker.num
        this.dealBetClass = true
        this.loadHistory();

      }else if(data.stage === 'deal_bet'){  //开始发牌下注阶段
        //设置状态
        this.currentGame.stage = 'deal_bet'
        this.usersBet('deal',data.dealBetRemainMillis)
        //重置下注信息
        this.resetMyBetList()
        //设置漂屏文字
        this.noticeText = 'Deal Bet Start'
        this.noticeAnimate = true
        this.dealBetClass = true
        
        //设置下注倒计时
        this.currentGame.dealBetRemainMillis = data.dealBetRemainMillis

      }else if(data.stage === 'deal_wait'){  //开始发牌待阶段
        this.resetMyBet()
        this.currentGame.stage = 'deal_wait'
        this.dealBetClass = true
        // this.waitNext = true
      }else if(data.stage === 'deal_animate'){  //发牌动画阶段
        this.currentGame.stage = 'deal_animate'
        this.dealBetClass = true
        // this.waitNext = true
        let n = Math.round(data.dealAnimateStartMillis / 500)
        if((n%2) !== 0){ n++ }
        let i = 0
        let length = data.dealCards.length - n
        this.timer = setInterval(() => {
          if(i < length){
            this.dealCards[i] = data.dealCards[ n + i]
            this.currentGame.cards = n + i + 1
            i++
          }else{
            this.pkWinShow = true,
            //i = 0
            window.clearInterval(this.timer);
          }
        },500);

      }else if(data.stage === 'deal_end'){  //发牌结束阶段
        this.currentGame.stage = 'deal_end'
        this.pkWinSide = data.side
        this.waitNext = true
        this.loadHistory();
      }
      this.currentGame.jokerBetRemainMillis = Date.now() + data.jokerBetRemainMillis
      this.currentGame.dealAnimateStartMillis = Date.now() + data.dealAnimateStartMillis
      this.currentGame.dealBetRemainMillis = Date.now() + data.dealBetRemainMillis

      if (isNotBlank(data.joker)) {
        this.joker.suit = data.joker.suit
        this.joker.num = data.joker.num
      }

      if(isNotBlank(data.appTaskStatus)){
        this.appTaskReward = data.appTaskReward
        this.appTaskStatus = data.appTaskStatus
      }
      //游客id
      if (isNotBlank(data.vtoken)) {
        localStorage.setItem("vtoken", data.vtoken);
      }

      //初始化socket
      this.initSocket();

      //加载历史
      this.loadHistory();

      //设置最后进入的游戏
      setLastTimeGameName('andar','lobby')

      Toast.hide("loading");

    })
  },

  // ======================================== beforeRouteLeave ========================================
  beforeRouteLeave() {
    //断开游戏socket
    disconnectSocket('andar', this.socket);
  },
  // ======================================== methods ========================================
  methods: {
    textToast(msg) {
      Toast.loading(msg, {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
    },
    //重置当前下注数据
    resetMyBet(){
      this.noticeAnimate = false
      this.showBetPanel = false
      this.pkWinShow = false,
      this.currentGame.cards = 0
      this.myBet.betAmount = 0
      this.myBet.betType = ''
      this.myBet.betTarget = ''
    },
    //重置本局下注订单
    resetMyBetList(){
      this.myBet.totalWinAmount = 0
      this.myBet.suit = ''
      this.myBet.suitAmount = 0
      this.myBet.num_invl = ''
      this.myBet.num_invlAmount = 0
      this.myBet.side = ''
      this.myBet.sideAmount = 0
      this.myBet.seq_invl = ''
      this.myBet.seq_invlAmount = 0
    },
    //其他玩家下注
    usersBet(_type,time){
      //下注人数列表
      this.usersBetList = []
      //下注总人数
      let total = 10000
      //当前下注人
      let i = 0
      //下注剩余时间
      let betDeadline = this.gameInit.betDeadline * 1000
      if(time){ betDeadline = time }
      //下注间隔
      let t = 100
      //循环下注
      const setBet = () => {
        if(i < total && betDeadline > 0){
          //头像
          let avatar = 'a' + Math.trunc(Math.random()*(10 - 1) + 1) + '.png'
          //下注类型
          let betType = ''     
          //下注目标
          let betTarget = ''
          if(_type === 'joker'){
            betType = parseProbabilityTarget('20%>suit;80%>num_invl;')
            if(betType === 'suit'){
              betTarget = parseProbabilityTarget('25%>spade;25%>heart;25%>club;25%>diamond;')
              if(isBlank(betTarget)){betTarget = 'spade'}
            }else{
              betType = 'num_invl'
              betTarget = parseProbabilityTarget('40%>below_7;40%>above_7;20%>exactly_7;')
              if(isBlank(betTarget)){betTarget = 'below_7'}
            }
          }else if(_type === 'deal'){
            betType  = parseProbabilityTarget('80%>side;20%>seq_invl;')
            if(betType === 'side'){
              betTarget = parseProbabilityTarget('50%>andar;50%>bahar;') 
              if(isBlank(betTarget)){betTarget = 'andar'}
            }else{
              betType = 'seq_invl'
              betTarget = parseProbabilityTarget('27.1%>1_5;21.71%>6_10;16.9%>11_15;21.81%>16_25;6.09%>26_30;3.7%>31_35;1.9%>36_40;0.79%>41_;')
              if(isBlank(betTarget)){betTarget = '6_10'}
            }
          }
          //手机号
          let mobile = "* " + Math.trunc(Math.random()*(9999 - 1000) + 1000) 
          //下注金额
          let originBetAmount = parseProbabilityTarget('23.7%>10;17%>20;24%>50;25%>100;5%>200;2%>300;2%>500;0.5%>1000;0.5%>2000;0.1%>3000;0.1%>4000;0.1%>5000')

          if(isBlank(originBetAmount)){
            originBetAmount = this.currentGame.minBetAmount
          }
          //下注间隔
          t = Math.trunc(Math.random()*(1000 - 1) + 1)
          
          this.usersBetList.unshift({
            avatar: avatar,
            betType: betType,
            betTarget: betTarget,
            mobile: mobile,
            originBetAmount: originBetAmount,
          });
          //下注剩余时间
          betDeadline = betDeadline - t
          
          i++
          if (this.usersBetList.length >= 20) {
            this.usersBetList.pop()
          }
          this.interval = setTimeout(setBet, t);
        }else{
          window.clearInterval(this.interval);
          i = 0
          betDeadline = this.gameInit.betDeadline * 1000
        }
      }
      this.interval = setTimeout(setBet, t);
    },

    initSocket() {
      if (this.socket != null) {
        return;
      }
      // 创建
      this.socket = createSocket('andar', this.bigwinData, this.broadcastMsgData);

      // 就绪
      this.socket.on(commonEvent.ready, () => {
        this.socket.emit(commonEvent.join);
        this.loadingbox = false
        this.waitNext = false
      });                
      /**
       * 开始小丑牌下注阶段
       */
      this.socket.on(this.eventEnum.jokerBet, (data) => {
        this.waitNext = false
        //重置牌位置
        this.currentGame.paiAnimate = ' '
        //重置小丑牌
        this.currentGame.jokerAnimation = false
        this.joker.suit = ''
        this.joker.num = ''
        this.pkWinShow = false,
        this.dealBetClass = false
        //重置其他牌
        this.dealCards = []
        //设置状态
        this.currentGame.stage = 'joker_bet'
        this.gameInit.gamePaused = false
        //重置下注信息
        this.resetMyBetList()
        //设置漂屏文字
        this.noticeText = this.$t('andarBahar.Place_your_bets')
        this.noticeAnimate = true
        //设置serialNum
        this.currentGame.serialNum = data.serialNum
        //设置下注倒计时
        this.currentGame.jokerBetRemainMillis = Date.now() + this.gameInit.betDeadline * 1000
        //其他人下注
        this.usersBet('joker')
        setTimeout(() => {
          this.waitNext = false
        }, 500);
      });

      /**
       * 开始小丑牌等待阶段
       */
      this.socket.on(this.eventEnum.jokerWait, () => {
        this.resetMyBet()
        setTimeout(() => {
          this.dealBetClass = false
        }, 500);
        this.currentGame.stage = 'joker_wait'
        this.currentGame.paiAnimate = 'joker-wait'
        setTimeout(() => {
          this.currentGame.jokerAnimation = true
        }, 2500);
      });

      /**
       * 小丑牌结束阶段
       */
      this.socket.on(this.eventEnum.jokerEnd, (data) => {
        this.currentGame.stage = 'joker_end'
        this.joker.suit = data.joker.suit
        this.joker.num = data.joker.num
        this.loadHistory();
      });

      /**
       * 开始发牌下注阶段
       */
      this.socket.on(this.eventEnum.dealBet, () => {
        //设置状态
        this.currentGame.stage = 'deal_bet'
        this.dealBetClass = false
        //重置下注信息
        this.resetMyBetList()
        //设置漂屏文字
        this.noticeText = this.$t('andarBahar.Place_your_bets')
        this.noticeAnimate = true
        //其他人下注
        this.usersBet('deal')
        //设置下注倒计时
        this.currentGame.dealBetRemainMillis = Date.now() + this.gameInit.betDeadline * 1000
      });

      /**
       * 开始发牌待阶段
       */
      this.socket.on(this.eventEnum.dealWait, () => {
        this.resetMyBet()
        this.currentGame.stage = 'deal_wait'
        this.dealBetClass = true
        this.currentGame.paiAnimate = 'joker-wait'
      });

      /**
       * 发牌动画阶段
       */
      this.socket.on(this.eventEnum.dealBetAnimate, (data) => {
        this.currentGame.stage = 'deal_animate'
        let i = 0
        let length = data.dealCards.length
        this.timer = setInterval(() => {
          if(i < length){
            this.dealCards[i] = data.dealCards[i]
            this.currentGame.cards = i + 1
            i++
          }else{
            this.pkWinShow = true,
            i = 0
            window.clearInterval(this.timer);
          }
        }, 500);
      });

      /**
       * 发牌结束阶段
       */
      this.socket.on(this.eventEnum.dealEnd, (data) => {
        this.currentGame.stage = 'deal_end'
        this.pkWinSide = data.side
        setTimeout(() => {
          //this.dealBetClass = false
        }, 500);
        this.loadHistory();
      });

      //结算信息
      this.socket.on(this.eventEnum.settle, (data) => {
        this.me.availableAmount = data.availableAmount
        this.me.chipAmount = data.chipBalance
        if(this.me.vipLevel === 0 && this.me.chipAmount >= 100){
          this.rechargePopup = true
        }
        this.myBet.totalWinAmount = data.totalWinAmount
      });
    },
    //加载历史记录
    loadHistory() {
      axios.get("/api/andar/history/paginate?current=1")
          .then((res) => {
            this.histories = res.data.data.records.reverse()
      });
    },
    //唤起下注面板
    betClick(betType, betTarget, disabled){
      if(disabled){return}
      if(this.currentGame.stage === 'joker_bet' || this.currentGame.stage === 'deal_bet'){
        this.showBetPanel = true
        this.myBet.betType = betType
        this.myBet.betTarget = betTarget
        this.myBet.betAmount = this.gameInit.recommendBetAmountList[this.currentGame.btnActiveInx]
      }else{
        console.log("Not betting time!")
      }
    },
    //下注按钮
    btnBetClick(a, i) {
      this.currentGame.btnActiveInx = i
      if(i === this.gameInit.recommendBetAmountList.length){
        if(a >= this.currentGame.maxBetAmount){
          this.myBet.betAmount = this.currentGame.maxBetAmount
        }else if(a < this.currentGame.minBetAmount){
          Toast.fail("Min bet " + this.currentGame.minBetAmount)
          this.myBet.betAmount = this.currentGame.minBetAmount
          return
        }else{
          this.myBet.betAmount = a
        }
      }else{
        this.myBet.betAmount = a
      }
    },
    //提交下注
    bet() {

      if (isBlank(this.myBet.betAmount) || this.myBet.betAmount <= 0) {
        Toast.fail(this.$t('PARITY.PLEASE_CONFIRM_YOUR_INPUT'))
        return;
      }
      this.myBet.betting = true;
      axios.post("/api/andar/bet",
          {
            betAmount: this.myBet.betAmount,
            betType: this.myBet.betType,
            betTarget:this.myBet.betTarget
          }
      ).then((res) => {
        this.myBet.betting = false;
        let data = res.data.data;
        if (res.data.code === 0) {
          //this.showBetPanel = false;
          this.currentGame.playing = true
          Toast.success(this.$t('global.successfully') + ' ' + this.myBet.betAmount);
          switch (this.myBet.betType) {
            case 'suit':
              this.myBet.suit = this.myBet.betTarget
              this.myBet.suitAmount += this.myBet.betAmount
              break;
            case 'num_invl':
              this.myBet.num_invl = this.myBet.betTarget
              this.myBet.num_invlAmount += this.myBet.betAmount
              break;
            case 'side':
              this.myBet.side = this.myBet.betTarget
              this.myBet.sideAmount += this.myBet.betAmount
              break;
            case 'seq_invl':
              this.myBet.seq_invl = this.myBet.betTarget
              this.myBet.seq_invlAmount += this.myBet.betAmount
              break;
          }
          this.me.availableAmount = data.availableAmount;
          this.me.chipAmount = data.chipBalance
        } else if (res.data.bizCode === 'GAME_BET_NEED_WITHDRAW') {
          this.showBetPanel = false;
        } else if(res.data.bizCode === 'FUND_CHIP_BALANCE_UP_TO_LIMIT'){
          //筹码满100
          this.rechargePopup = true
        }
      })
    },
    //关闭结算面板
     closeSettlePanel() {
      this.showSettlePanel = false;
      this.gamePrepare(this.currentGame.rowCount)
    },
    //去充值
    rechargeClick() {
      if(this.commonInfo.firstRechargeAvailable){
        router.push('/firstRechargeBonus');
      }else{
        router.push('/rechargeBalance');
      }
    },
    //去提现
    withdrawClick() {
      router.push('/withdrawalBalance?amountType=coin');
    },
    rulePopClick(){
      this.rulePopup = true
    },
    vipPopupClick(){
      router.push({
          name: "vipDetails",
        });
    },
    //下注加减
    btnAddition() {
      if (isBlank(this.myBet.betAmount)) {
        this.myBet.betAmount = this.currentGame.defaultBetAmount
        return
      }
      if (this.myBet.betAmount <= 99) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 10)
      } else if (this.myBet.betAmount <= 999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 100)
      } else if (this.myBet.betAmount <= 9999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 1000)
      } else if (this.myBet.betAmount <= 99999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 10000)
      } else if (this.myBet.betAmount <= 999999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 100000)
      } else if (this.myBet.betAmount <= 9999999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 1000000)
      } else if (this.myBet.betAmount <= 999999999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 1000000)
      }
      if (this.myBet.betAmount > this.currentGame.maxBetAmount) {
        Toast.text("Maxmum of " + this.currentGame.maxBetAmount);
        this.myBet.betAmount = this.currentGame.maxBetAmount
      }
    },
    btnSubtract() {
      if (isBlank(this.myBet.betAmount) || this.myBet.betAmount <= this.currentGame.minBetAmount) {
        Toast.text("Minimum of " + this.currentGame.minBetAmount);
        return
      }
      if (this.myBet.betAmount <= 100) {
        if (this.myBet.betAmount > this.currentGame.minBetAmount)this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 10)
      } else if (this.myBet.betAmount <= 1000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 100)
      } else if (this.myBet.betAmount <= 10000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 1000)
      } else if (this.myBet.betAmount <= 100000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 10000)
      } else if (this.myBet.betAmount <= 1000000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 100000)
      } else if (this.myBet.betAmount <= 10000000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 1000000)
      } else if (this.myBet.betAmount <= 1000000000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 1000000)
      }
      if (this.myBet.betAmount < this.currentGame.minBetAmount) {
        this.myBet.betAmount = this.currentGame.minBetAmount
      }
    },
    //其他玩家的订单
    playersList() {
      this.orderTabActive = true
    },
    //我的订单
    myHistoryList() {
      this.orderTabActive = false
      axios({
        url: "/api/andar/order/paginate",
        method: "get",
        params: {
          current: 1,
          queryLimit: 'today'
        }
      }).then((res) => {
        const data = res.data.data;
        this.myPlays = data.records
      });
    },

    //跳转到我的下注历史
    toMyPlayList() {
      router.push({path: "myPlayListAndar"});
    },
    
    //跳转到游戏大厅
    toGameLobby() {
      router.push({path: "lobby"});
    },

    commonInfoAttr(key) {
      return getCommonInfoAttr(key);
    },

    //下载app
    downloadApp(){
      window.open(getCommonInfoAttr('appDownloadUrl'))
    },

    parseAmount(amount) {
      if (isBlank(amount)) {
        return null;
      }
      return this.$math.format(amount, (value) => value.toFixed(2));
    },

  },
};
</script>

<style scoped src="../assets/css/game_ab.css"></style>
<style scoped>
</style>
<style>
.pop-mine-inventory{ background-color: transparent !important;}
.betPanelAB {border-radius: 10px 10px 0 0 !important;}
.betPanelAB .btn-confirm .nut-button--round{border-radius:8px !important;}
</style>



