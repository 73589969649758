<template>
<div>
  <fullscreen v-model="fullscreen">
  
    <div class="back-btn"  @click="backClick"></div>

    <div class="iframe-box" :style="{ height: iframeBoxHeight }">
      <iframe  width="100%" :height="iframeHeight" :src="iframeSrc" class="iframe" ref="iframe"></iframe>
    </div>
    
    <div class="loading-box"  v-if="loading"><p><strong>Game loading...</strong></p></div>

  </fullscreen>
  </div>
</template>

<script>
import {reactive, toRefs, onMounted} from "vue";
import {useRouter, useRoute} from "vue-router";
import { setLastTimeGameName } from "@/assets/js/environment";
import axios from "axios";

export default {
  data(){
    return{
      iframeBoxHeight:'380px',
      iframeHeight:'100%',
      
    }
  },
  mounted(){
    // this.$refs.iframe.addEventListener('orientationchange',()=>{
    // });
    window.addEventListener('resize',()=>{
      //document.documentElement.requestFullscreen()
      this.iframeBoxHeight = window.innerHeight + 'px';
      this.iframeHeight = window.innerHeight + 'px';
    })
    this.$refs.iframe.addEventListener('load',()=>{
      this.loading = false
    });
  },
  methods: {

  },

  created() {
    //document.documentElement.requestFullscreen()
    this.iframeBoxHeight = window.innerHeight + 'px';
  },

  setup() {
    const state = reactive({
      loading: true,
      commonInfo: '',
      id: '',
      name: 'WinZone789',
      platform: '',
      iframeSrc: '',
      url: window.location.href,
      fullscreen: false,
    });

    const router = useRouter();
    const route  = useRoute();
    const methods = {
      backClick() {
        router.push({path: "lobby"});
      },

    };
    onMounted(() => {
      state.fullscreen = true
      state.commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
      state.id = route.query.id;
      if(route.query.name){
        state.name = route.query.name;
      }
      if(route.query.platform){
        state.platform = route.query.platform;
      }
      
      axios
      .post("/api/game/enter-external-game", {
        gameId: state.id,
        returnUrl: state.url,
      })
      .then((res) => {
        if (res.data.code === 0) {
          state.iframeSrc = res.data.data.gameUrl
          setLastTimeGameName(state.id,state.platform)
        }else if(res.data.code === 1001) {
          router.push({path: "userRegister"});
        }else{
          router.push({path: "lobby"});
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
  .iframe-box{ width: 100%; height:100%; position: relative;}
  .iframe{ width: 100%; height: 100%; position: relative; border: 0 !important; margin: 0; padding: 0;}
  .back-btn{ z-index: 2000; position: fixed; left: 5px; top:5px; width: 40px; height: 40px; 
  background: #1f0830 url(../assets/images/global_icon/202405-72.png) center center no-repeat; background-size: 21px auto; border-radius: 25px;}

  .loading-box{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30000;
  background:#000;
}

.loading-box p {
  position: fixed;
  text-align: center;
  color: #fff;
  background: #000 url(../assets/images/global/bg509.gif) center 0 no-repeat;
  background-size: 100px auto;
  left: calc(50% - 100px);
  top: calc(50% - 150px);;
   width: 200px;
   height: 100px;
  font-size: 20px;margin: 0; padding: 0;
}
.loading-box p strong{ padding-top: 120px; line-height: 30px; display: block;}
</style>
