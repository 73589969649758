<template>
<div>
  <fullscreen v-model="fullscreen">
    <div class="back-btn"  @click="backClick"></div>

    <div class="iframe-box" :style="{ height: iframeBoxHeight }">
      <iframe  width="100%" :height="iframeHeight" :src="iframeSrc" class="iframe" ref="iframe"></iframe>
    </div>

    <div class="loading-box"  v-if="loading">

      <div class="tx">
        <p><strong>Game loading...</strong></p>

        <div class="t1">
          <div class="p">You can choose <span class="c-r">Baccarat</span>(<span class="c-y">24 hours</span>) hosted by a real beauty</div>
        </div>
        <div class="t2">
          <div class="p">or the exciting and interesting <span class="c-r">Live Game Marble Race</span>(<span class="c-y">9:30--21:30</span>)</div>
        </div>
        
      </div>
    </div>

  </fullscreen>
</div>
</template>

<script>
import {reactive, toRefs, onMounted} from "vue";
import {useRouter, useRoute} from "vue-router";
import { setLastTimeGameName } from "@/assets/js/environment";
import axios from "axios";

export default {
  data(){
    return{
      iframeBoxHeight:'380px',
      iframeHeight:'100%',
    }
  },
  mounted(){
    window.addEventListener('resize',()=>{
      //document.documentElement.requestFullscreen()
      this.iframeBoxHeight = window.innerHeight + 'px';
      this.iframeHeight = window.innerHeight + 'px';
    })
    this.$refs.iframe.addEventListener('load',()=>{
      this.loading = false
    });
  },
  methods: {
  },
  created() {
    //document.documentElement.requestFullscreen()
    this.iframeBoxHeight = window.innerHeight + 'px';
  },
  setup() {
    const state = reactive({
      loading: true,
      commonInfo: '',
      id: '',
      name: 'WinZone789',
      platform: '',
      iframeSrc: '',
      url: window.location.href,
      fullscreen: false
    });

    const router = useRouter();
    const route  = useRoute();
    const methods = {
      backClick() {
        router.push({path: "lobby"});
      },

    };
    onMounted(() => {
      state.fullscreen = true
      state.commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
      state.id = route.query.id;
      if(route.query.name){
        state.name = route.query.name;
      }
      if(route.query.platform){
        state.platform = route.query.platform;
      }
      
      axios
      .post("/api/game/enter-external-game", {
        gameId: state.id,
        returnUrl: state.url,
      })
      .then((res) => {
        if (res.data.code === 0) {
          state.iframeSrc = res.data.data.gameUrl
          setLastTimeGameName(state.id,state.platform)
        }else if(res.data.code === 1001) {
          router.push({path: "userRegister"});
        }else{
          router.push({path: "lobby"});
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
  .iframe-box{ width: 100%; height:100%; position: relative;}
  .iframe{ width: 100%; height: 100%; position: relative; border: 0 !important; margin: 0; padding: 0;}
  .back-btn{ z-index: 2000; position: fixed; left: 5px; top:5px; width: 40px; height: 40px; 
  background: #1f0830 url(../assets/images/global_icon/202405-72.png) center center no-repeat; background-size: 21px auto; border-radius: 25px;}





</style>
